.chart {
    .title-chart {
        text-transform: uppercase;
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 3rem;
    }
}

@media screen and (max-width: 767px) {
    .title-chart {
        margin-bottom: 0;
        font-size: 2rem;
    }

}
