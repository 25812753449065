@use 'sass:map';
@use '../theming' as *;

@mixin clearfix {
	&::after {
		content: "";
		display: table;
		clear: both;
	}
}

@mixin cart-icon-wrapper-button {
	position: relative;
	border: none;

	span {
		$magic_size: 24px;
		display: block;
		position: absolute;
		top: -0.25 * $magic_size;
		left: 1.25 * $magic_size;
		width: $magic_size;
		height: $magic_size;
		font-size: 0.5 * $magic_size;
		line-height: $magic_size;
		text-align: center;
		background-color: map.get($theme,accent-black);
		color: map.get($theme,accent-white);
		border-radius: 100%;
	}

	div {
		&.cart-label {
			color: map.get($theme,accent-black);
			float: left;
			margin-top: map.get($theme,m1);
			margin-left: map.get($theme,m5);
			font-size: 15px;
			font-weight: 600;
			animation: single-blink 1.5s linear 3;
		}
	}

	&.disabled {
		opacity: 0.2;
		cursor: inherit;

		div {
			&.cart-label {
				opacity: 0;
			}
		}
	}

	@media screen and (max-width: 1172px) {
		div {
			&.cart-label {
				display: none;
			}
		}
	}
}

@mixin buttons_wrapper {
	span {
		display: inline-block;
		margin: 2rem;
		font-size: 1.5rem;
	}

	.outlined_cta {
		width          : 220px;
		text-transform : capitalize;
		font-weight    : bold;

		&.large_cta {
			width          : 300px;
			text-transform : initial;
		}

		&.accept {
			color        : map.get($theme,accent-dark-green);
			border-color : map.get($theme,accent-dark-green);
		}

		&.reject {
			color        : map.get($theme,accent-red);
			border-color : map.get($theme,accent-red);
		}

		&.cancel {
			color        : map.get($theme,color-greyscale-dark);
			border-color : transparent;
			font-weight  : normal;
		}
	}
}

@mixin outlined_buttons {
	.outlined {
		&_card {
			width: 100%;
			padding: 4rem;
			border: 1px solid map.get($theme,accent-black);
			border-radius: 1rem;
		}

		&_cta {
			appearance: none;
			font-size: 2.4rem;
			letter-spacing: 0.1rem;
			text-align: center;
			height: 65px;
			width: 300px;
			max-width: 100%;
			border: 1px solid map.get($theme,accent-black);
			border-radius: 0 50px 50px;
			background: transparent;

			&[disabled] {
				opacity: .2;
			}

			&:not([disabled]):hover {
				cursor: pointer;
				background: rgba(#fff, .2);
			}
		}
	}
}

@mixin apply_and_clear_ctas {
	margin-top: 3rem;
	.apply_filters {
		margin-right: map.get($theme,m1);
		color: map.get($theme,color-greyscale-white);
		background-color: map.get($theme,color-greyscale-dark);
		border-radius: map.get($theme,radius-small);
	}

	.clear_filters {
		border: solid 2px map.get($theme,color-greyscale-dark);
		border-radius: map.get($theme,radius-small);
		&.hidden {
			visibility: hidden;
		}
	}
}

@mixin price {
	em {
		display         : block;
		font-size       : map.get($theme,font1);
		font-weight     : 300;
		cursor          : help;
	}

	strong {
		display     : block;
		font-size   : map.get($theme,font2);
		font-weight : 500;
		cursor      : help;
	}
}

@mixin card-layout {
	border: 1px solid map.get($theme,color-greyscale-medium);
	margin-bottom: map.get($theme,m5);
	padding: map.get($theme,m5);
}

@mixin button {
	text-align: center;
	font-size: 1.5rem;
	letter-spacing: 2px;
	font-weight: 500;
	text-transform: uppercase;
	border-radius: 4px;
	white-space: nowrap;
}

@mixin cta-back {
	display: inline-block;
	padding: 0.5rem 1.5rem;
	color: map.get($theme,accent-white);
	background-color: map.get($theme,accent-black);
	text-transform: uppercase;
	font-size: 1.5rem;
	letter-spacing: 2px;
	cursor: pointer;
}





@mixin ecomm-product-image-small {
	display          : flex;
	align-items      : center;
	margin-right     : map.get($theme,m5);
	min-width        : 220px;
	min-height       : 220px;
	background-color : map.get($theme,accent-white);

	img {
		display      : block;
		margin-left  : auto;
		margin-right : auto;
		max-width    : 200px;
		max-height   : 200px;
	}
}

@mixin colore-stato-ordine {
	&.annullato {
		color : map.get($theme,accent-red);
	}

	&.confermato, &.completato {
		color : map.get($theme,color-success-medium);
	}
}
