@use 'sass:map';
@use '../theming' as *;

.custom-dropdown {
  position: relative;
}

.custom-dropdown .dropdown-options {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 4px;
  max-height: 200px; /* Altezza massima del dropdown per la scorrevolezza */
  overflow-y: auto; /* Abilita lo scrolling verticale */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: none; /* Nascondi il dropdown di default */
}

.custom-dropdown.open .dropdown-options {
  display: block; /* Mostra il dropdown quando è aperto */
}

.custom-dropdown label {
  display: block;
  padding: 8px 10px;
  cursor: pointer;
}

.custom-dropdown input[type="checkbox"] {
  margin-right: 10px;
}

.custom-dropdown input[type="checkbox"]:checked + span {
  font-weight: bold;
}

.button-disabled{
  background-color: gray;
}

.tesy_view_newsletter_crea,
.tesy_view_newsletter_modifica {
  .ts_select1,
  .form-control,
  .ts_textarea1,
  .search-wrapper {
    border: 1px solid map.get($theme,color-greyscale-dark);
    background-color: white;
  }
  .form-group .form-control,
  .ts_textarea1,
  .search-wrapper .searchBox {
    font-size: map.get($theme,font2);
  }
}
