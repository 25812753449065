@use "sass:color";
@use 'sass:map';
@use '../theming' as *;

input[type="radio"],
input[type="checkbox"] {
  margin-top: 2px; // for a better vertical alignment
}

input + label {
  cursor: pointer;
}

input:disabled + label {
  color: map.get($theme,color-greyscale-disabled);
  cursor: default;
}

.ts_input1 {
  border-radius: map.get($theme,radius-medium);
  border: solid 2px map.get($theme,color-greyscale-dark);
  height: map.get($theme,m6);
  padding: 0 map.get($theme,m2);
  font-size: map.get($theme,font2);
  width: 100%;
}

.ts_textarea0 {
  border-radius: map.get($theme,radius-medium);
  border: solid 2px map.get($theme,color-greyscale-dark);
  min-height: 6rem;
  padding: map.get($theme,m2);
  margin-bottom: 0;
  font-size: map.get($theme,font2);
  width: 100%;
}

.ts_textarea1 {
  border-radius: map.get($theme,radius-medium);
  border: solid 2px map.get($theme,color-greyscale-dark);
  min-height: 16rem;
  padding: map.get($theme,m2);
  font-size: map.get($theme,font3);
  width: 100%;
}

.assistenza-textbox {
  height: 100%;
}

.ts_select1 {
  padding: 0 map.get($theme,m2);
  font-size: map.get($theme,font2);
  height: map.get($theme,m6);
  width: 100%;
  transition: map.get($theme,speed-fast);
  border-radius: map.get($theme,radius-medium);
  border: solid 2px map.get($theme,color-greyscale-dark);
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: radio-container;
  appearance: none;
  color: black;
  background-color: white;

  &:focus {
    outline: none;
    background-color: map.get($theme,color-greyscale-lighter);
  }
}

.ts_select2 {
  .multiselect-container {
    .search-wrapper {
      padding: 0.3rem 0.5rem;
      font-size: map.get($theme,font2);
      //height: map.get($theme,m6);
      //width: 100%;
      transition: map.get($theme,speed-fast);
      border-radius: map.get($theme,radius-medium);
      border: solid 2px map.get($theme,color-greyscale-dark);
      color: black;
      background-color: white;

      &:focus {
        outline: none;
        background-color: map.get($theme,color-greyscale-lighter);
      }
    }
  }
}

.ts_select1_wrap {
  width: 100%;
  position: relative;
  height: map.get($theme,m6);

  &:before {
    content: "\f078";
    font-weight: 900;
    display: block;
    font-family: "Font Awesome 5 Free";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    transform: translateY(-50%);
    color: map.get($theme,color-greyscale-darker);
    font-size: map.get($theme,font2);
    pointer-events: none;
  }
}

.ts_button1 {
  border-radius: map.get($theme,radius-medium);
  height: map.get($theme,m6);
  padding: 0 map.get($theme,m4);
  font-size: map.get($theme,font2);
  color: map.get($theme,color-greyscale-white);
  background-color: map.get($theme,color-greyscale-dark);
  border: solid 2px map.get($theme,color-greyscale-dark);
  transition: map.get($theme,speed-fast);
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: transparent;
    color: map.get($theme,color-greyscale-dark);
  }

  i {
    font-size: 1.7rem;
    margin-right: map.get($theme,m2);
  }

  &.center {
    display: block;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  &:disabled {
    opacity: 0.35;

    &:hover {
      background-color: map.get($theme,color-greyscale-dark);
      color: map.get($theme,color-greyscale-white);
    }
  }
}

.ts_button2 {
  border-radius: map.get($theme,radius-medium);
  height: 2.7rem;
  width: 100%;
  padding: 0 map.get($theme,m4);
  font-size: map.get($theme,font1);
  color: map.get($theme,color-greyscale-white);
  background-color: map.get($theme,color-greyscale-dark);
  border: solid 2px map.get($theme,color-greyscale-dark);
  transition: map.get($theme,speed-fast);
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: transparent;
    color: map.get($theme,color-greyscale-dark);
  }

  i {
    font-size: 1.7rem;
    margin-right: map.get($theme,m2);
  }
}

.ts_button3 {
  border-radius: map.get($theme,radius-medium);
  height: map.get($theme,m6);
  padding: 0 map.get($theme,m4);
  font-size: map.get($theme,font2);
  color: map.get($theme,color-greyscale-white);
  background-color: map.get($theme,color-greyscale-dark);
  border: solid 2px map.get($theme,color-greyscale-dark);
  transition: map.get($theme,speed-fast);
  font-weight: 700;
  cursor: pointer;

  &:hover {
    background-color: transparent;
    color: map.get($theme,color-greyscale-dark);
  }
}

.ts_button4 {
  background-color: transparent;
  color: black;
  border: none;
  font-size: 2rem;
}

.ts_button5 {
  background-color: transparent;
  color: black;
  border: none;
  font-size: 1.5rem;
}

.ts_button6 {
  background-color: transparent;
  color: black;
  border: none;
  font-size: 1.4rem;
  font-weight: bold;
  text-decoration: underline;
}

.ts_button7 {
  position: absolute !important;
  top: 7px;
  left: 22px;

  border-radius: map.get($theme,radius-medium);
  height: map.get($theme,m6);
  padding: 0 map.get($theme,m4);
  font-size: 1.5rem;
  color: map.get($theme,color-greyscale-white);
  background-color: map.get($theme,color-greyscale-dark);
  border: solid 2px map.get($theme,color-greyscale-dark);
  transition: map.get($theme,speed-fast);
  font-weight: 300;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    //background-color: transparent;
    //color: map.get($theme,color-greyscale-dark);
  }

  i {
    font-size: 1.7rem;
    margin-right: map.get($theme,m2);
  }

  &:disabled {
    opacity: 0.35;

    &:hover {
      background-color: map.get($theme,color-greyscale-dark);
      color: map.get($theme,color-greyscale-white);
    }
  }
}

.btn_filter {
  width: auto !important;
}

.label-error {
  color: red;
}

.ts_buttoninline1 {
  height: map.get($theme,m6);
  padding: 0 map.get($theme,m4);
  font-size: map.get($theme,font2);
  color: map.get($theme,color-greyscale-dark);
  border: solid 2px transparent;
  transition: map.get($theme,speed-fast);
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  i {
    font-size: 1.7rem;
    margin-right: map.get($theme,m2);
  }
}

.ts_link0 {
  font-size: map.get($theme,font1);
  text-decoration: underline;
  cursor: pointer;
}

.ts_link1 {
  font-size: map.get($theme,font2);
  text-decoration: underline;
  cursor: pointer;
}

.close_section {
  display: inline-block;
  margin-left: map.get($theme,m3);
  cursor: map.get($theme,color-greyscale-dark);
}

// variazioni
.btn_color1 {
  background-color: rgb(217, 119, 6);
  border-color: rgb(217, 119, 6);

  &:hover {
    border-color: map.get($theme,color-greyscale-dark);
  }
}

.btn_color2 {
  color: white;
  background-color: #17a2b8;
  border-color: #17a2b8;

  &:hover {
    border-color: map.get($theme,color-greyscale-dark);
  }
}

.btn_color3 {
  color: white;
  background-color: #ee3e4d;
  border-color: #ee3e4d;

  &:hover {
    border-color: map.get($theme,color-greyscale-dark);
  }
}

.btn_color4 {
  color: white;
  background-color: #fc5c09;
  border-color: #fc5c09ff;

  &:hover {
    border-color: map.get($theme,color-greyscale-dark);
  }
}

.btn_color_transparent {
  color: map.get($theme,color-greyscale-dark) !important;
  background-color: transparent !important;
  border-color: map.get($theme,color-greyscale-dark) !important;

  &:hover {
    color: map.get($theme,accent-red) !important;
    border-color: map.get($theme,accent-red) !important;
  }
}

.ts_button_secondary {
  background-color: map.get($theme,color-secondary-medium);
  border-color: map.get($theme,color-secondary-medium);

  &:hover {
    border-color: map.get($theme,color-greyscale-dark);
  }
}

.ts_button_confirm {
  background-color: map.get($theme,color-secondary-medium);
  border-color: map.get($theme,color-secondary-medium);
  width: 100%;
  display: flex;
  justify-content: center;

  &:hover {
    border-color: map.get($theme,color-greyscale-dark);
  }
}

.ts_button_cancel {
  background-color: #ee3e4d;
  border-color: #ee3e4d;
  width: 100%;
  display: flex;
  justify-content: center;

  &:hover {
    border-color: map.get($theme,color-greyscale-dark);
  }
}

.grid_action2 {
  border: solid 1px transparent;
  padding: 0 0.6rem;
  height: map.get($theme,m5) * 1.2;
  min-width: map.get($theme,m5) * 1.4;
  border-radius: map.get($theme,radius-medium);
  font-size: 1rem;
  text-align: right;
  color: white;
  text-transform: uppercase;
  font-weight: 400;
  cursor: pointer;
  letter-spacing: 0.05em;
  transition: map.get($theme,speed-fast);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  background-color: #536d81;
  position: relative;

  i {
    font-size: map.get($theme,font1) * 0.8;
  }
}
.grid_action3 {
  border: solid 1px transparent;
  padding: 0 0.6rem;
  height: map.get($theme,m5) * 1.2;
  min-width: map.get($theme,m5) * 1.4;
  border-radius: map.get($theme,radius-medium);
  font-size: 1rem;
  text-align: right;
  color: white;
  text-transform: uppercase;
  font-weight: 400;
  cursor: pointer;
  letter-spacing: 0.05em;
  transition: map.get($theme,speed-fast);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  background-color: transparent;
  position: relative;

  i {
    font-size: map.get($theme,font1) * 0.8;
  }
  .svg-ico {
    max-width: 20px;
  }

  &.disabled {
    cursor: auto;
    opacity: 0.3;
  }
}

.grid_action1 {
  border: solid 1px transparent;
  padding: 0 0.6rem;
  height: map.get($theme,m5) * 1.2;
  min-width: map.get($theme,m5) * 1.4;
  border-radius: map.get($theme,radius-medium);
  font-size: 1rem;
  text-align: left;
  color: white;
  text-transform: uppercase;
  font-weight: 400;
  cursor: pointer;
  letter-spacing: 0.05em;
  transition: map.get($theme,speed-fast);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  background-color: map.get($theme,color-greyscale-dark);
  position: relative;

  i {
    font-size: map.get($theme,font1) * 0.8;
  }

  &:last-of-type {
    margin-right: 0;
  }

  span {
    margin-left: 0.8rem;
  }

  small {
    color: white;
    margin-left: 2px;
    font-size: 0.7rem;
    margin-right: -1px;
  }
}

.grid_action0 {
  border: solid 1px transparent;
  padding: 0 0.6rem;
  height: 2.4rem;
  min-width: 2.8rem;
  border-radius: 0.6rem;
  font-size: 1rem;
  text-align: left;
  color: white;
  text-transform: uppercase;
  font-weight: 400;
  cursor: pointer;
  letter-spacing: 0.05em;
  transition: 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  background-color: none !important;
  position: relative;
}

.blocked-order {
  border-radius: 50%;
  border: #ee3e4d;
  height: map.get($theme,m4);
  width: map.get($theme,m4);
  background-color: #ee3e4d;
}

.suborders {
  border-radius: 50%;
  border: #94c3ff;
  height: map.get($theme,m4);
  width: map.get($theme,m4);
  background-color: #94c3ff;
}

[class*="ts_button"],
.grid_action1 {
  position: relative;

  .badges {
    position: absolute;
    right: -6px;
    top: -9px;

    .badge {
      position: relative;
      right: auto;
      top: auto;
    }
  }

  .badge {
    position: absolute;
    right: -6px;
    top: -9px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: map.get($theme,color-greyscale-white);
  }

  &.cta {
    background-color: map.get($theme,color-success-medium-alt);
    border-color: map.get($theme,color-success-medium-alt);
    &:hover {
      color: set-text-color(map.get($theme,color-success-medium-alt));
      //background-color: color.adjust(map.get($theme,color-success-medium-alt), $lightness: -5%); // TODO sass to css5 refactor needed
      //border-color: color.adjust(map.get($theme,color-success-medium-alt), $lightness: -5%); // TODO sass to css5 refactor needed
    }
    &.disabled {
      opacity: 0.7;
      cursor: auto !important;
      background-color: map.get($theme,color-greyscale-disabled);
      border-color: map.get($theme,color-greyscale-disabled);
    }
  }
}
