@use "sass:color";
@use 'sass:map';
@use '../theming' as *;

.dashboard-wrapper {
  min-height:90vh;
}

.tesy_view_home {
  .ts_view_content {
    padding-bottom: 0;
  }
}

.ts_dashboard_row {
  margin-bottom: 3rem;
  margin-bottom: #{"max(3vh,20px)"};
}

.dot-green {
  height: 25px;
  width: 25px;
  background-color: map.get($theme,color-success-medium)_alt;
  border-radius: 50%;
  display: inline-block;
}
.dot-red {
  height: 25px;
  width: 25px;
  background-color: #c93b3b;
  border-radius: 50%;
  display: inline-block;
}

.dot-yellow {
  height: 25px;
  width: 25px;
  background-color: #ffcc00;
  border-radius: 50%;
  display: inline-block;
}

.ts_dashboard_widget {
  border-radius: map.get($theme,radius-medium);
  position: relative;
  cursor: pointer;
  min-height: 200px;
  height: 25.5vh;
  background-color: map.get($theme,accent-black);

  .splited_card & {
    overflow: hidden;
    min-height: unset;
    height: 12vh;
    &:first-child {
      margin-bottom: 1.5vh;
    }
  }

  &:hover {
    .ts_dashboard_widget_title {
      opacity: 0.8;
    }
  }

  &.loading::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    opacity: 0.55;
    background-color: map.get($theme,accent-white);
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle%3E.spinner_OSmW%7Btransform-origin:center;animation:spinner_T6mA .75s step-end infinite%7D@keyframes spinner_T6mA%7B8.3%25%7Btransform:rotate(30deg)%7D16.6%25%7Btransform:rotate(60deg)%7D25%25%7Btransform:rotate(90deg)%7D33.3%25%7Btransform:rotate(120deg)%7D41.6%25%7Btransform:rotate(150deg)%7D50%25%7Btransform:rotate(180deg)%7D58.3%25%7Btransform:rotate(210deg)%7D66.6%25%7Btransform:rotate(240deg)%7D75%25%7Btransform:rotate(270deg)%7D83.3%25%7Btransform:rotate(300deg)%7D91.6%25%7Btransform:rotate(330deg)%7D100%25%7Btransform:rotate(360deg)%7D%7D%3C/style%3E%3Cg class='spinner_OSmW'%3E%3Crect x='11' y='1' width='2' height='5' opacity='.14'/%3E%3Crect x='11' y='1' width='2' height='5' transform='rotate(30 12 12)' opacity='.29'/%3E%3Crect x='11' y='1' width='2' height='5' transform='rotate(60 12 12)' opacity='.43'/%3E%3Crect x='11' y='1' width='2' height='5' transform='rotate(90 12 12)' opacity='.57'/%3E%3Crect x='11' y='1' width='2' height='5' transform='rotate(120 12 12)' opacity='.71'/%3E%3Crect x='11' y='1' width='2' height='5' transform='rotate(150 12 12)' opacity='.86'/%3E%3Crect x='11' y='1' width='2' height='5' transform='rotate(180 12 12)'/%3E%3C/g%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    -webkit-transition: all 3s;
    transition: all 3s;
  }

  i {
    position: absolute;
    z-index: 1;
    top: 1rem;
    right: 1rem;
    color: map.get($theme,accent-white);
    font-size: 12rem;
    opacity: 0.3;
  }

  /*.dot-red {
        height: 25px;
        width: 25px;
        background-color: #c93b3b;
        border-radius: 50%;
        display: inline-block;
    }

    .dot-yellow {
        height: 25px;
        width: 25px;
        background-color: #c93b3b;
        border-radius: 50%;
        display: inline-block;
    }*/

  .ts_dashboard_widget_title {
    background-color: #e6b35b;
    height: 100%;
    transition: opacity 500ms;
    border-radius: map.get($theme,radius-medium);

    &.color1 {
      background-color: #e6b35b;
    }

    &.color2 {
      background-color: #8e9c8e;
    }

    &.color3 {
      background-color: #4e8d6e;
    }

    &.color4 {
      background-color: #bcbc90;
    }

    &.color5 {
      background-color: #71a3b8;
    }

    &.color6 {
      background-color: #89896f;
    }

    &.color7 {
      background-color: #d5d596;
    }
  }

  .ts_dashboard_widget_cta {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;

    .ts_dashboard_widget_cta_number {
      color: map.get($theme,accent-white);
      font-size: map.get($theme,font6);
      padding: map.get($theme,m2) map.get($theme,m3) 0 map.get($theme,m3);
      line-height: 1;

      &._shy {
        line-height: 0.8;
      }
    }

    .ts_dashboard_widget_cta_title {
      color: map.get($theme,accent-white);
      font-size: map.get($theme,font4);
      padding: map.get($theme,m2) map.get($theme,m3) map.get($theme,m2) map.get($theme,m3);
      line-height: 1;
    }

    .ts_dashboard_widget_cta_subtitle {
      color: map.get($theme,accent-white);
      font-size: map.get($theme,font1);
      padding: 0 map.get($theme,m3) map.get($theme,m2) map.get($theme,m3);
      letter-spacing: 1px;
      line-height: 1;
    }

    .ts_dashboard_widget_cta_btn {
      background-color: map.get($theme,color-greyscale-dark);
      color: map.get($theme,color-greyscale-white);
      font-size: map.get($theme,font2);
      text-transform: uppercase;
      padding: map.get($theme,m2) map.get($theme,m3) map.get($theme,m2) map.get($theme,m3);
      font-weight: 700;
      border-bottom-left-radius: map.get($theme,radius-medium);
      border-bottom-right-radius: map.get($theme,radius-medium);
    }
  }

  &_academy {
    cursor: default !important;
    padding: 2rem 1.3rem 1rem 1.3rem !important;

    &,
    * {
      color: map.get($theme,color-greyscale-white);
    }

    .inner_link {
      cursor: pointer !important;
    }

    &_tilted_link {
      font-style: italic;
      text-decoration: underline;
      margin-left: 8px;
    }

    &_title {
      font-size: map.get($theme,font4);
      letter-spacing: 1px;
      line-height: 1;
      font-weight: 400;
    }

    &_ct {
      font-size: map.get($theme,font2);
      font-weight: 400;
    }

    .scrollbar-container {
      display: flex;
      max-width: 100%;
      padding-bottom: 4rem;

      .ps__rail-x {
        opacity: 1 !important;
        background: #c7c7c7;
        border-radius: 10px;
        height: 6px;
        -webkit-transition: background-color 0.2s linear,
          height 0.2s ease-in-out;

        .ps__thumb-x {
          bottom: 0;
          background: #2a2a2a;
        }

        &:focus-within,
        &:hover {
          &,
          .ps__thumb-x {
            height: 11px;
          }
        }
      }
    }

    &_lessons {
      margin-top: 4rem;
    }

    &_lesson_card {
      background: map.get($theme,accent-black);
      width: 500px;
      display: block;
      height: 280px;
      border-radius: 8px;
      padding: 2rem;

      &_content {
        max-width: 270px;

        &,
        * {
          line-height: 1.4;
        }

        span {
          overflow: hidden;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
          word-break: break-word;
          min-height: 72px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      &:not(:last-child) {
        margin: 0 30px 0 0;
      }
    }

    .play-icon {
      font-size: 120px;
      color: #2827ff;
    }

    .ts_dashboard_widget_cta_btn {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .ribbon {
    overflow: hidden;
    position: absolute;
    top: -10px;
    left: -10px;
    width: 120px;
    height: 120px;

    .ribbon-content {
      position: absolute;
      display: block;
      left: -30px;
      top: 12px;
      width: 120px;
      padding: 5px 0;
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
      background-color: map.get($theme,accent-red);
      color: #fff;
      font-size: 17px;
      font-weight: bold;
      letter-spacing: 2px;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      text-transform: uppercase;
      text-align: center;

      &::before,
      &::after {
        position: absolute;
        content: "";
        display: block;
        border: 8px solid transparent;
      }

      &::before {
        top: 35px;
        right: 0;
        //border-top-color: color.adjust(map.get($theme,accent-red), $lightness: -20%); // TODO sass to css5 refactor needed
        //border-right-color: color.adjust(map.get($theme,accent-red), $lightness: -20%); // TODO sass to css5 refactor needed
      }

      &::after {
        bottom: -15px;
        left: 0px;
        //border-top-color: color.adjust(map.get($theme,accent-red), $lightness: -20%); // TODO sass to css5 refactor needed
        //border-left-color: color.adjust(map.get($theme,accent-red), $lightness: -20%); // TODO sass to css5 refactor needed
      }
    }
  }
}

.swiper {
  width: 85%;
  height: 100%;
  position: relative; // Aggiungi questa riga per impostare la posizione relativa
}

.dashboard-video {
  .dashboard_chart_layout {
    padding-bottom: 40px !important;
  }
  .bg-dark {
    background-color: map.get($theme,accent-medium-green) !important;
    cursor: default !important;
    .ts_dashboard_widget_cta_btn {
      cursor: pointer !important;
    }
  }
}

.video-row {
  overflow: auto;
  height: 80%;
  margin-left: map.get($theme,m3);
  margin-right: map.get($theme,m3);

  @media screen and (min-width: 1400px) {
    .col-xxl-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
  }

  .video-thumb {

    margin-bottom : map.get($theme,m5);

    .video-thumb-wrapper {

      position: relative;
      min-height: 15vh;
      margin-bottom : map.get($theme,m1);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-color: map.get($theme,accent-video-thumb-bkg);
      box-shadow: 0 2px 5px rgba(map.get($theme,accent-black), 0.25);
      cursor: pointer !important;

      .video-academy-image {
        display: none;
      }

      .titolo-video-academy {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: map.get($theme,m2) map.get($theme,m4);
        background-color: rgba(map.get($theme,accent-black), 0.75);
        h3 {
          color: map.get($theme,accent-white);
          font-size: map.get($theme,font1);
          letter-spacing: 1px;
          text-transform: uppercase;
        }
      }
      .ribbon-badge {
        top: 10px;
        right: -10px;
      }
    }

    .video-category {
      color: map.get($theme,accent-white);
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    //.sottotitolo-video-accademy {
    //  font-size: 14px;
    //  line-height: 1.15;
    //  font-weight: lighter;
    //  color: #ffffff;
    //  font-family: "Roboto", sans-serif; /* Assuming "Roboto" is the desired font */
    //  margin-bottom: 8px;
    //}

    //.video-category {
    //  font-size: 13px;
    //  color: map.get($theme,accent-white);
    //  font-weight: lighter;
    //}

    //.nome-categoria-video-accademy {
    //  font-weight: lighter;
    //  font-family: "Roboto", sans-serif; /* Assuming "Roboto" is the desired font */
    //  color: #f2f2f2;
    //  text-align: right;
    //  margin-top: 4%;
    //}

    //.titolo-video-accademy {
    //  font-size: 18px;
    //  line-height: 1.15;
    //  font-weight: bold;
    //  font-family: "Roboto", sans-serif; /* Assuming "Roboto" is the desired font */
    //  color: #f2f2f2;
    //  text-align: left;
    //}

    //.video-academy-image {
    //  width: 90%;
    //  height: 90%;
    //  object-fit: cover;
    //
    //  @media screen and (max-width: 500px) {
    //    width: 100%;
    //    height: 100%;
    //  }
    //}

  }
}

.ts_dashboard_widget_cta_btn {
  @media screen and (max-width: 991px) {
    bottom: 0;
    left: 0;
    right: 0;
  }
  @media screen and (min-width: 991px) {
    bottom: 0;
    left: 0;
    right: 0;
  }
}


.overlay {
  max-width: 80%;
  max-height: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 16px;
}

.topnordini_box {
  border-radius: 0.6rem;
  overflow: hidden;
  position: relative;
  //cursor: pointer;
  min-height: 200px;
  height: 25.5vh;
  background-color: #445b6c;
  padding: map.get($theme,m2) map.get($theme,m3) map.get($theme,m2) map.get($theme,m3);

  .topnordini_title {
    color: map.get($theme,accent-white);
    font-size: map.get($theme,font4);
    line-height: 1;
    padding-top: map.get($theme,m2);
  }

  .topnordini_table {
    padding-top: map.get($theme,m2);

    .dx-datagrid-headers {
      color: map.get($theme,accent-white);
    }

    .dx-datagrid-content {
      color: map.get($theme,accent-white);
    }

    .dx-datagrid-content .dx-datagrid-table.dx-datagrid-table-fixed {
      //color: map.get($theme,accent-white);
    }
  }
}

.last_messages_box {
  border-radius: 0.6rem;
  overflow: hidden;
  position: relative;
  min-height: 200px;
  height: 25.5vh;
  //background-color: #445b6c;
  background-color: #779289;
  padding: map.get($theme,m2) map.get($theme,m3) map.get($theme,m2) map.get($theme,m3);

  .last_messages_title {
    color: map.get($theme,accent-white);
    font-size: map.get($theme,font4);
    line-height: 1;
    padding-top: map.get($theme,m1);
  }

  .messages_wrp {
    display: flex;
    height: 14vh;
    margin-top: map.get($theme,m5);
    .messages_inner {
      flex: 0 0 100%;
      /*overflow: scroll;*/
    }
    .message {
      margin-bottom: map.get($theme,m5);
      color: map.get($theme,accent-white);
      line-height: initial;
      strong {
        font-size: map.get($theme,font2);
        line-height: map.get($theme,font2);
        font-weight: normal;
        &:hover {
          text-decoration: underline;
        }
      }
      span {
        display: inline-block;
        padding-top: map.get($theme,m1);
        font-size: map.get($theme,font0);
        line-height: map.get($theme,font0);
        letter-spacing: 1px;
        font-weight: normal;
      }
    }
  }
}

.ts_dashboard_chart_title {
  color: map.get($theme,accent-white);
  font-weight: 300;
  font-size: 6rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  letter-spacing: -0.05em;
}

.ts_dashboard_widget_icon {
  background-color: #5ea17f;
  color: map.get($theme,accent-white);
  width: 4rem;
  height: 4rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: map.get($theme,radius-medium);
  font-size: 2rem;
  margin-right: map.get($theme,m2);

  &.active {
    background-color: #ddca99;
  }
}

.ts_dashboard_widget_stats_title {
  color: map.get($theme,accent-white);
  font-size: map.get($theme,font1);
  font-weight: 700;
  margin: 0 0 0.1rem 0;
  opacity: 0.8;
}

.ts_dashboard_widget_stats_subtitle {
  color: map.get($theme,accent-white);
  font-size: map.get($theme,font1);
  font-weight: 400;
  opacity: 0.6;
  margin: 0;
}

.ts_dashboard_widget_title2 {
  color: map.get($theme,accent-white);
  font-size: map.get($theme,font4);
}

.dashboard-chart-header {
  .t2 {
    font-size: 3rem;
  }
}

.dashboard_chart {

  &.loading::after {
    height: 54vh;
  }

  .video-academy-title {
    color: map.get($theme,accent-white);
    font-size: map.get($theme,font4);
    line-height: 1;
    margin: map.get($theme,m2) map.get($theme,m3) map.get($theme,m2) map.get($theme,m3);
  }

  .dashboard_chart_layout {
    border-radius: map.get($theme,radius-medium);
    position: relative;
    height: 54vh;
    min-height: 420px;
    padding: map.get($theme,m3);
    background-color: #d9d9d9;
    cursor: pointer;
    overflow: hidden;
  }

  .ts_dashboard_widget_cta_btn {
    background-color: map.get($theme,color-greyscale-dark);
    color: map.get($theme,color-greyscale-white);
    font-size: map.get($theme,font2);
    text-transform: uppercase;
    padding: map.get($theme,m2) map.get($theme,m3) map.get($theme,m2) map.get($theme,m3);
    font-weight: 700;
  }

  .dashboard_chart_footer {
    border-bottom-left-radius: map.get($theme,radius-medium);
    border-bottom-right-radius: map.get($theme,radius-medium);
    background-color: map.get($theme,color-greyscale-dark);
    color: map.get($theme,color-greyscale-white);
    font-size: map.get($theme,font2);
    text-transform: uppercase;
    padding: map.get($theme,m2) map.get($theme,m3) map.get($theme,m2) map.get($theme,m3);
    font-weight: 700;
  }

}

.video-thumb {

}

@media screen and (max-width: 1024px) and (max-height: 768px) {
  .dashboard_chart {
    min-height: unset;
    height: auto;
    //margin-bottom: 6.5rem;
  }
}

@media screen and (max-width: 768px) and (max-height: 1024px) {
  .dashboard_chart {
    min-height: unset;
    height: auto;
  }
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  .dashboard_chart {
    //margin-bottom: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .ts_dashboard_row {
    margin-bottom: map.get($theme,m4);
  }

  .ts_dashboard_widget {
    margin-bottom: 2rem;
  }
}
