.gallery-finishes {
	height: 100%;

	.gallery-btn {
		display: flex;
		align-items: center;
		justify-content: start;
		font-size: 15px;
		cursor: pointer;

		i {
			margin-right: 1rem;
		}
	}

	.gallery-thumb-wrapper {
		position: relative;
		&:hover {
			.gallery-thumb-hidden {
				z-index: 30;
			}
		}
		.gallery-thumb-visible {
			position: relative;
			background-color: white;
			z-index: 20;

		}
		.gallery-thumb-hidden {
			background-color: white;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 10;
		}
	}

	.gallery-finishes-divider {
		margin-left: 7px;
		margin-right: 7px;
		font-size: 25px;
	}
}

@media screen and (max-width: 500px) {
	.gallery-finishes {
		.gallery-finishes-divider {
			display: none;
		}
	}
}
