$family            : 'Roboto', sans-serif;
$family-standalone : 'Helvetica Neue', sans-serif;

$fontweights: 100, 300, 400 ,500 ,700, 900;

$typography        : (
        app-font: $family,
        app-standalone-pages-font: $family-standalone,
        font0: 1rem,
        font1: 1.2rem,
        font2: 1.5rem,
        font3: 2.1rem,
        font4: 2.6rem,
        font5: 4rem,
        font6: 6.6rem,
        fontweights: $fontweights
);

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family : 'Helvetica Neue';
  src         : local('Helvetica Neue'),
                url('../fonts/Helvetica-Neue-Font/HNB/HNB.ttf') format('truetype');
}
