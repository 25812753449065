@use "sass:color";
@use 'sass:map';
@use '../theming' as *;
@use "mixins";

.ribbon-badge {
  position: absolute;
  top: 30px;
  right: 10px;
  display: inline-block;
  padding: 5px 20px;
  background-color: map.get($theme,color-error-medium);
  color: map.get($theme,accent-white);
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;

  &::after {
    position: absolute;
    content: "";
    display: block;
    border: 5px solid transparent;
    bottom: -10px;
    right: 0px;
    border-top-color: color.adjust(map.get($theme,color-error-medium), $lightness: -20%);
    border-left-color: color.adjust(map.get($theme,color-error-medium), $lightness: -20%);
  }

  &.updated {
    background-color: map.get($theme,color-yellow-300);
    color: map.get($theme,accent-black);

    &::after {
      border-top-color: color.adjust(map.get($theme,color-yellow-300), $lightness: -20%);
      border-left-color: color.adjust(map.get($theme,color-yellow-300), $lightness: -20%);
    }
  }

  &.new {
    background-color: map.get($theme,color-error-medium);
    color: map.get($theme,accent-white);

    &::after {
      border-top-color: color.adjust(map.get($theme,color-error-medium), $lightness: -20%);
      border-left-color: color.adjust(map.get($theme,color-error-medium), $lightness: -20%);
    }
  }

  &.recommended {
    background-color: map.get($theme,color-success-medium-alt);
    color: map.get($theme,accent-white);

    &::after {
      border-top-color: color.adjust(map.get($theme,color-success-medium-alt), $lightness: -20%);
      border-left-color: color.adjust(map.get($theme,color-success-medium-alt), $lightness: -20%);
    }
  }
}

.product-add-remove {
  padding: 1px;
  margin-right: map.get($theme,m2);
  line-height: calc(4rem - 8px);
  height: auto;
  background-color: white;

  @media screen and (max-width: 767px) {
    width: auto;
  }

  .quantity {
    display: inline-block;
    width: 4rem;
    text-align: center;
    border: none;

    /* Remove Arrows/Spinners */
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }

  .remove,
  .add {
    display: inline-block;
    width: 3rem;
    text-align: center;
    color: white;
    background-color: map.get($theme,color-greyscale-black);
    border: 1px solid transparent;
  }

  .remove {
    border-radius: map.get($theme,radius-small) 0 0 map.get($theme,radius-small);
  }

  .add {
    border-radius: 0 map.get($theme,radius-small) map.get($theme,radius-small) 0;
  }
}

.content-wrapper {
  .svg-ico {
    display: block;
    width: 35px;
    height: auto;
    &.small {
      max-width: 20px;
      margin-left: map.get($theme,m1);
      opacity: 0.5;
    }
  }

  .nothing-to-show {
    text-align: center;
    margin-top: 50vh;
    transform: translateY(-100%);

    h1 {
      margin-bottom: 2rem;
    }

    h2 span {
      font-size: 0.75em;
      letter-spacing: 1px;
      font-weight: normal;
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .page-standalone & {
      margin-top: 40vh;
      h2 {
        margin-bottom: 2rem;
      }
      .back-to-page span {
        display: inline-block;
        padding: map.get($theme,m3) map.get($theme,m5);
      }
    }
  }

  .button {
    @include mixins.button;

    &.primary {
      border: 2px solid map.get($theme,accent-dark-green);
      background-color: map.get($theme,accent-dark-green);
      color: map.get($theme,accent-white);
    }

    &.secondary {
      border: 2px solid map.get($theme,color-greyscale-dark);
      color: map.get($theme,color-greyscale-dark);
      &.bg-white {
        background-color: map.get($theme,accent-white);
      }
    }

    &.disabled {
      opacity: 0.2;
      cursor: default;
    }

    &.small {
      height: map.get($theme,m2) * 3;
      font-size: map.get($theme,font1);
      letter-spacing: 1px;
    }

    &.cart {
      border: 2px solid map.get($theme,accent-black);
      background-color: map.get($theme,accent-black);

      .svg-ico {
        max-width: 20px;
        // https://codepen.io/sosuke/pen/Pjoqqp to retrieve this filter rule
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(201deg)
          brightness(100%) contrast(100%);
      }
    }

    &.download-manual {
      img {
        max-width: 25px;
        margin-right: 5px;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  .back-to-page {
    margin-bottom: map.get($theme,m7);
    text-align: center;

    .tesy_view_carrello & {
      float: left;
      margin-left: 2rem;
    }

    span {
      @include mixins.button;
      @include mixins.cta-back;
    }
  }

  .content-list {
    padding: map.get($theme,m5);

    .title {
      font-weight: normal;

      .date-ins {
        font-size: map.get($theme,font0);
        font-weight: normal;
      }
    }

    .content-filters {
      .checkbox-list {
        .filter-depth-0 {
          &:not(:first-child) {
            margin-top: map.get($theme,m1);
            padding-top: map.get($theme,m1);
            border-top: 1px solid map.get($theme,color-greyscale-medium);
          }

          .label {
            font-weight: bold;
          }
        }

        @for $i from 0 through 10 {
          .filter-depth-#{$i} {
            padding-left: (20px * $i);
          }
        }
      }

      .label .count {
        font-weight: lighter;
        color: map.get($theme,color-greyscale-dark);
      }
    }

    .item-card {
      @include mixins.card-layout;
    }
  }

  .cart-wrapper {
    padding: map.get($theme,m5);

    .cart-message-area {
      border: 2px solid;
      padding: map.get($theme,m5);
      text-align: center;
      border-radius: map.get($theme,radius-small);

      &.message-info {
        border-color: map.get($theme,color-success-medium-alt);
        background-color: rgba(map.get($theme,color-success-medium-alt), 0.15);
      }

      &.message-warning {
        border-color: map.get($theme,color-orange-300);
        background-color: rgba(map.get($theme,color-orange-300), 0.15);
      }

      &.message-error {
        border-color: map.get($theme,accent-red);
        background-color: rgba(map.get($theme,accent-red), 0.1);
        .message-content {
          color: map.get($theme,accent-red);
          font-weight: 500;
        }
      }

      .message-content {
        font-size: map.get($theme,font2);
        font-weight: 300;
      }
    }

    $image_size: 180px;

    .product-card-wrapper {
      width: 100%;
      margin-bottom: 2% !important;

      .product-card {
        width: 95%;
        padding: map.get($theme,m2);

        border: 0;
        border-radius: map.get($theme,radius-small);
        background-color: map.get($theme,accent-white);

        .item-wrapper {
          width: 50%;
          padding-left: 2%;
        }
        .unit-price-qty-wrapper {
          width: 30%;
        }
        .item-total-price-wrapper {
          width: 20%;
          padding-right: 2%;
        }

        .price-wrapper {
          text-align: right;

          .price-label {
            display: block;
            margin-bottom: map.get($theme,m2);
            color: rgba(map.get($theme,accent-black), 0.6);
            font-size: map.get($theme,font1);
            font-weight: 400;
          }
          .single-price {
            font-size: map.get($theme,font2);
            font-weight: 500;
          }
          .subtotal {
            font-size: map.get($theme,font4);
            font-weight: 500;
          }
        }

        .preview {
          width: $image_size;
          height: $image_size;
          margin-right: map.get($theme,m4);
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;
          img {
            display: none;
          }
        }
      }

      .remove-item {
        width: 5%;
        cursor: pointer;
        &.disabled {
          cursor: default;
          opacity: 0.2;
        }
      }
    }

    .product-total-price {
      margin-bottom: map.get($theme,m8);
      margin-right: 5%;
      padding-right: 3rem;
      align-items: center;
      span {
        display: block;
        width: 70%;
        padding-left: calc(3% + #{$image_size} + #{map.get($theme,m4)});
        font-size: map.get($theme,font2);
        font-weight: bold;
        text-transform: uppercase;
      }
      strong {
        display: block;
        width: 30%;
        text-align: right;
        font-size: map.get($theme,font4);
      }
    }

    .expo-state {
      display: block;
      width: 20px;
      height: 20px;

      border: 1px solid;
      border-radius: map.get($theme,radius-small);

      &.inizio {
        border-color: color.adjust(map.get($theme,color-greyscale-medium), $lightness: -10%);
        background-color: map.get($theme,color-greyscale-medium);
      }

      &.in_lavorazione {
        border-color: color.adjust(map.get($theme,color-orange-300), $lightness: -10%);
        background-color: map.get($theme,color-orange-300);
      }

      &.completato {
        border-color: color.adjust(map.get($theme,color-success-medium), $lightness: -10%);
        background-color: map.get($theme,color-success-medium);
      }

      span {
        display: none;
      }
    }
  }

  .cart-additional-info {
    border-radius: map.get($theme,radius-small);
    background-color: map.get($theme,color-primary-lighter);
    margin-bottom: map.get($theme,m6);
    padding: map.get($theme,m6) map.get($theme,m8);

    .title {
      margin-bottom: map.get($theme,m6);
    }

    .datepicker-order-cause-wrapper,
    .shipping-addresses {
      margin-bottom: map.get($theme,m6);
    }

    .delivery-date,
    .other-params {
      min-width: 300px;
      margin-right: map.get($theme,m4);
    }
  }
}
