@use 'sass:map';
@use '../theming' as *;

$magic_size: 2.4rem;

.custom-paginator-wrapper {

	margin-bottom: $magic_size*0.5;

	&.top {
		margin-top: $magic_size*-1;
		@media screen and (max-width : 500px) {
			margin-top: $magic_size*0.5;
		}
	}

	ul {
		display: flex;
		justify-content: flex-end;

		list-style: none;

		li {
			display: inline-block;
			width: $magic_size*1.2;
			height: $magic_size*1.2;
			margin-right: $magic_size*0.25;

			font-size: $magic_size*0.5;
			line-height: $magic_size*1.2;
			text-align: center;

			user-select: none;
			cursor: pointer;

			border-radius: map.get($theme,radius-small);

			&.active {
				cursor: default;
				background-color: map.get($theme,accent-pagination);
				font-weight: bold;
			}

			&.disabled {
				cursor: default;
				color: rgba(map.get($theme,color-active),0.25);
			}

			&:hover:not(.active):not(.disabled) {
				background-color: rgba(map.get($theme,accent-pagination),0.25);
			}
		}
	}
}
