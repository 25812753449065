@use 'sass:map';
@use '../theming' as *;

.academy-wrapper {

  .academy-video-list-header {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (max-width : 450px) {
      .title {
        margin-bottom: map.get($theme,m2);
      }
    }
  }

  .scroll-to-section {
    display: none;
    @media screen and (max-width : 380px) {
      display: block;
    }
  }

  .academy-inner {
    display        : flex;
    flex-direction : row;

    @media screen and (max-width : 380px) {
      flex-direction : column-reverse;
    }

    .academy-filters {
      min-width    : 15vw;
      margin-right : 2rem;

      @media screen and (max-width : 1400px) {
        min-width: 20vw;
      }
      @media screen and (max-width : 1172px) {
        min-width: 25vw;
      }
      @media screen and (max-width : 570px) {
        min-width: 40vw;
      }
      @media screen and (max-width : 380px) {
        min-width: unset;
        margin-right: 0;
      }
    }

    .academy-video-grid {
      width      : 100%;
      place-self : flex-start;

      .reset-filter-wrapper {
        position : absolute;
        left     : 50%;
        top      : 10%;
      }
    }
  }

  .bullets {
    font-size     : 1.2rem;
    list-style    : none;
    margin-bottom : 1rem;
    padding       : 0;

    li {
      display      : inline-block;
      margin-right : 1rem;
    }

    .svg-ico {
      width         : 15px;
      margin-bottom : -2px;
    }

    &.has-attachments .svg-ico {
      width         : 12px;
      margin-bottom : -5px;
    }

    &.tags li:not(:first-child):not(:last-child) {
      margin-right : 0.5rem;

      &::after {
        content : ', ';
      }
    }

    &.categories li:not(:first-child):not(:last-child) {
      margin-right : 0.3rem;

      &::after {
        content : ' > ';
      }
    }

    &.has-attachments {
      .button.secondary {
        max-width  : unset;
        margin-top : map.get($theme,m2);

        .svg-ico {
          margin-bottom : 2px;
          margin-right  : map.get($theme,m2);
        }

        a {
          letter-spacing : 0px;
          text-transform : none;
          color          : map.get($theme,accent-black);
        }
      }
    }
  }

  .academy-filters {
    .svg-ico {
      display : inline-block;
      width   : 20px;
      margin  : 0 10px 0 0;
    }

    .video-orederby .sort_filter {
      flex-direction  : row;
      align-self      : flex-end;
      justify-content : flex-start;
      width           : 100%;

      .sort_by_select {
        width : 100%;
      }
    }

    .sort_button {
      .order_by_asc {
        transform : scaleY(-1);
      }
    }

    .checkbox-list {
      .MuiFormControlLabel-root {
        margin-bottom : 0;
      }

      .MuiCheckbox-root {
        padding : 4px 9px;
      }

      .MuiTypography-root {
        font-size : map.get($theme,font1);
      }

      .reset-entity-checkbox {
        float          : right;
        padding        : 0;
        font-size      : map.get($theme,font0);
        text-transform : uppercase;

        span {
          display      : inline-block;
          margin-right : map.get($theme,m1);
        }
      }
    }
  }

  .video-card {
    position     : relative;
    width        : 24%;
    min-width    : 12vw;
    margin-right : 1%;

    @media screen and (max-width : 1400px) {
      width     : 32%;
      min-width : unset;
    }

    @media screen and (max-width : 1172px) {
      width : 49%;
    }

    @media screen and (max-width : 570px) {
      width        : 100%;
      margin-right : 0;
    }

    .preview {
      height              : 130px;
      width               : 100%;
      cursor              : pointer;

      background-color    : map.get($theme,accent-video-thumb-bkg);
      background-position : center center;
      background-size     : contain;
      background-repeat   : no-repeat;

      img {
        visibility : hidden;
        width      : 100%;
      }
    }

    .title {
      font-weight : bold;
      cursor      : pointer;
    }

    .description {
      font-size : 1.4rem;
    }

    .video-card-cta {
      margin-top : 4rem;
    }
  }
}

.academy-wrapper.single-wrapper {
  display        : flex;
  flex-direction : column;
  margin         : 20px;

  .categories.bullets {
    margin-bottom : map.get($theme,m1);
  }

  .has-attachments.bullets {
    margin-bottom : map.get($theme,m3);
  }

  h1 {
    margin-bottom : map.get($theme,m2);
    font-family : Roboto;
    font-weight : bold;
    color       : #212529;
    font-size   : 20px;
  }

  .button-wrapper {
    display         : flex;
    align-items     : center;
    align-content   : center;
    justify-content : center;

    .bottone-indietro {
      border-radius   : 6px;
      display         : flex;
      padding         : 8px;
      margin-top      : 20px;
      border          : 1px solid #212529;
      align-items     : center;
      align-content   : center;
      justify-content : center;
      text-transform  : uppercase;
      font-size       : 15px;
      @media screen and (max-width : 991px) {
        width : 40%;
      }
      @media screen and (min-width : 991px) {
        width : 20%;
      }
    }
  }

  .button.secondary {
    margin-top : map.get($theme,m5);
    max-width  : 250px;
  }

  .video-wrapper {
    overflow        : hidden;
    background      : map.get($theme,accent-medium-green);
    display         : flex;
    flex-wrap       : nowrap;
    align-content   : center;
    justify-content : center;
    align-items     : center;
    cursor          : pointer;
  }

  video {
    @media screen and (max-width : 991px) {
      width : 90%;
    }
    @media screen and (min-width : 991px) {
      width : 60%;
    }
  }

  .video-card.single {
    width           : 100%;
    margin-top      : 10px;
    padding         : 15px;
    position        : relative;
    display         : flex;
    flex-direction  : column;
    min-width       : 0;
    word-wrap       : break-word;
    background-clip : border-box;
    border          : 1px solid #cfcfcf;
    border-radius   : 0.25rem;

    .description {
      margin : map.get($theme,m3) map.get($theme,m1) map.get($theme,m5);

      p {
        margin-bottom   : map.get($theme,m3);
        text-decoration : black;
        font-weight     : normal;
        font-size       : map.get($theme,font2);
      }

      .date-ins {
        font-size : map.get($theme,font0);
      }
    }
  }


  .subtitle {
    font-size       : 12px;
    display         : flex;
    justify-content : center;
    padding         : 10px;
    flex-direction  : column;
    align-items     : flex-start;
    flex-wrap       : nowrap;

    .subtitle-row {
      display        : flex;
      flex-direction : row;
      flex-wrap      : nowrap;
      align-content  : center;
      align-items    : center;
      margin         : 5px;

      img {
        margin-right : 10px;
      }
    }

    .space {
      margin-right : 5px;
    }

    span {
      white-space : nowrap;
      display     : flex;
      display     : inline-block;
      overflow    : hidden;
    }
  }

  img {
    margin : 0.4%;
  }
}
