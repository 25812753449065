@use 'sass:map';
@use "sass:color";
@use '../theming' as *;

.ts_info_title {
    background-color: white;
    color: map.get($theme,color-greyscale-dark);
    border-color: white;
    transition: map.get($theme,speed-fast);
    display: flex;
    border-width: 1px;
    border-style: solid;
    padding: map.get($theme,m2);
    min-height: map.get($theme,m4);
    border-radius: map.get($theme,radius-small);
    font-size: map.get($theme,font2);
    text-align: left;
    text-transform: uppercase;
    font-weight: 400;
    cursor: pointer;
    align-items: center;
    cursor: text;
    margin-bottom: map.get($theme,m4);

    i {
        margin-right: 1rem;
    }

    h2 {
        font-size: map.get($theme,font2);
    }

}

.section_item {
    border-bottom: solid 1px map.get($theme,color-greyscale-dark);
    padding: 0 1rem 1rem 1rem;
    margin-bottom: map.get($theme,m2);
    color: map.get($theme,color-greyscale-dark);

    label {
        font-size: map.get($theme,font1);
    }

    p {
        font-size: map.get($theme,font2);
        font-weight: 700;
    }
}


.ts_info_item {
    border-bottom: solid 1px map.get($theme,color-greyscale-light);
    padding: 0 1rem 1rem 1rem;
    margin-bottom: 1rem;
}

.style_list_label {
    font-size: map.get($theme,font2);
    color: map.get($theme,color-greyscale-darker);
}

.h_label {
    height: 4rem;
}

.style_list_label2 {
    font-size: 1.8rem;
    color: map.get($theme,color-greyscale-black);
}

.form-input {
    height: calc(2.5rem + 0.75rem + 2px);
    font-size: 1.8rem;
    border-radius: 0.6rem;
    border: solid 2px #2A2A2A;
}

.form-group {
    .style_list_label_form {
        font-size: 2rem;
        color: map.get($theme,color-greyscale-darker);
    }

    .form-control {
        font-size: 2rem;
    }
}

.style_list_content {
    font-size: 1.3rem;
    font-weight: 700;
    color: map.get($theme,color-greyscale-darker);
}

.style_list_content a {
    color: map.get($theme,color-theme3);
    text-decoration: underline;
}


.ts_info_block {

    background-color: map.get($theme,color-greyscale-lighter);
    border-radius: map.get($theme,radius-medium);
    padding: 2rem 2rem;
    cursor: pointer;
    color: map.get($theme,color-greyscale-darker);
    transition: opacity map.get($theme,speed-fast);
    position: relative;

    h2, p {
        color: map.get($theme,color-greyscale-darker);
    }

    .ts_info_block_options {
        position: absolute;
        right: 1rem;
        top: 1rem;

        i:before {
            color: map.get($theme,color-greyscale-dark);
            transition: map.get($theme,speed-fast);
        }

        i:hover:before {
            color: map.get($theme,color-greyscale-darker);
        }

    }


    .ts_info_block_icon {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        text-align: center;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: map.get($theme,font3);
        font-weight: 400;
    }

}

.ts_info_link {
    cursor: pointer;
}

.ts_info_title {
    background-color: map.get($theme,color-greyscale-lighter);
    border-radius: map.get($theme,radius-medium);
    padding: 1rem 1rem;
    display: flex;
    align-items: center;

    &.ts_info_title_alert {
        background-color: map.get($theme,color-error-medium);
        border-color: color.adjust(map.get($theme,color-error-medium), $lightness: -10%);

        h2, i:before {
            color: white;
        }
    }

    &.ts_info_title_warning {
        background-color: map.get($theme,color-secondary-light);
        border-color: color.adjust(map.get($theme,color-secondary-light), $lightness: -10%);

        h2, i:before {
            color: map.get($theme,color-greyscale-darker);
        }
    }

    &.ts_info_title_success {
        background-color: map.get($theme,color-success-medium);
        border-color: color.adjust(map.get($theme,color-success-medium), $lightness: -10%);

        h2, i:before {
            color: white;
        }

    }

    &.ts_info_title_dark {
        background-color: map.get($theme,color-greyscale-dark);
        border-color: color.adjust(map.get($theme,color-greyscale-dark), $lightness: -10%);

        h2, i:before {
            color: white;
        }
    }

    h2 {
        color: map.get($theme,color-greyscale-darker);
        margin-left: 1rem;
    }
}

.ts_info_item {
    border-bottom: solid 1px map.get($theme,color-greyscale-light);
    padding: 0 1rem 1rem 1rem;
    margin-bottom: 1rem;
}

.ts_info_item_fullheight {
    height: 100%;
    padding-bottom: 0;
}

.ts_info_preview {
    cursor: pointer;
}

.document_folder_main {
    .ts_info_title:first-of-type {
        margin-top: 0 !important;
    }
}

.reclamo_section {
    margin-bottom: map.get($theme,m7);

    &.inactive {
        pointer-events: none;
        opacity: .5;

        .reclamo_actions {
            display: none;
        }
    }
}

.ts_picture_add {
    width: 110px;
    max-width: 30vw;
    text-align: center;

    p {
        //font-size: map.get($theme,font1);
        margin-bottom: 0;
        margin-top: map.get($theme,m1);
        text-transform: uppercase;
    }

    small {
        font-size: 0.9rem;
        color: #4e4e4e;
    }

    .ts_attachments_add_button {
        border-radius: map.get($theme,radius-medium);
        border: solid 1px white;
        height: 0;
        width: 100%;
        background-color: map.get($theme,color-greyscale-lighter);
        padding-bottom: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;

        i {
            font-size: 4rem;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 5rem;
            height: 5rem;
            color: map.get($theme,color-greyscale-dark);
        }

        &.disabled {
            cursor: default;
            opacity: 0.25;
        }

    }

}

.ts_picture_loaded {

    width: 110px;
    text-align: center;

    p {
        font-size: map.get($theme,font1);
        margin-bottom: 0;
        margin-top: map.get($theme,m1);
        text-transform: uppercase;
        text-decoration: underline;
        cursor: pointer;
    }

    .ts_picture_loaded_img {
        border-radius: map.get($theme,radius-medium);
        border: solid 1px white;
        height: 0;
        width: 100%;
        background-color: map.get($theme,color-greyscale-lighter);
        padding-bottom: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;

        .img_placeholder {
            i {
                font-size: 5rem;
            }
        }

        .img_placeholder,
        img {
            position: absolute;
            left: 0;
            top: 0;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    .remove-btn {
        cursor: pointer;
        i {
            font-size: 4rem;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 5rem;
            height: 5rem;
            color: map.get($theme,color-greyscale-light);
        }
    }

}

.box-info {
    border: 1px solid #EEEEEE;
    border-radius: 0.375rem;
    height: 100%;

    .box-info-header {
        background-color: #EEEEEE;
        display: flex;
        padding: 1.5rem;
    }

    .box-info-body {
        padding: 1.5rem;
    }
}
