.dx-tabpanel {
	background-color: white;
}

.dx-tabpanel > .dx-tabpanel-tabs .dx-tab.dx-tab-selected {
	background-color: white;
	font-weight: bold;
}

.academy_filemanager.dx-filemanager, .download_filemanager.dx-filemanager {
	.dx-filemanager-breadcrumbs {
		background-color: white;
	}

	.dx-drawer-panel-content {
		background-color: white;
	}

	.dx-datagrid-rowsview {
		.dx-row-focused.dx-data-row > td:not(.dx-focused) {
			background-color: #EEEEEE;
			color: black;
		}
		.dx-row-focused.dx-data-row:not(.dx-row-lines) > td {
			border: 0;
		}
	}

	.dx-row.dx-data-row {
		cursor: pointer;
	}

	.dx-last-data-cell {
		text-align: left !important;
	}

	.dx-icon-folder {
		color: rgba(253, 168, 2, 0.78) !important;
	}

	.dx-icon-video {
		color: royalblue;
	}

	.dx-icon-pdffile {
		color: #de2d2d;
	}
}

.academy_filemanager.dx-filemanager {
	.dx-datagrid-content {
		margin-left: 4px;
	}

	.dx-drawer-wrapper {
		min-height: 80vh;
	}

	.dx-filemanager-container {
		border-top: none;
	}

	.dx-filemanager-toolbar {
		display: none;
	}

	.dx-row {
		.dx-filemanager-file-actions-button {
			display: none;
		}
	}

	.dx-filemanager-dirs-tree.dx-treeview {
		.dx-filemanager-file-actions-button {
			.dx-button {
				.dx-button-content {
					display: none;
				}
			}
		}
	}
}

.download_filemanager.dx-filemanager {
	 /*.dx-filemanager-files-view.dx-filemanager-details .dx-row-focused .dx-filemanager-file-actions-button .dx-button .dx-icon {
		color: black;
	}*/
	.dx-filemanager-files-view.dx-filemanager-details {
		.dx-row-focused {
			.dx-filemanager-file-actions-button {
				.dx-button {
					.dx-icon {
						color: black;
					}
				}
			}
		}
	}
}
