@use 'sass:meta';
@use 'sass:map';
@use '../../active-theme' as *;
@use '../../theming' as *;


@if meta.global-variable-exists('active-theme') and $active-theme == 'primacucine' {
  body.primacucine {

    div.error403 {
      $sidebar: map.get($theme,sidebar-width);
      width: calc(100% - $sidebar);
    }

    .ts_sidebar_header img {
      width : 17rem;
    }

    .ts_sidebar_item .ts_sidebar_item_title {
      padding: 1.3rem 2rem;
    }

    .ts_sidebar_minimized img {
      width: 4rem;
      height: 4rem;
    }

    .ts_input1 {
      border           : none;
      background-color : transparent;
      border-radius    : 0;
      border-bottom    : solid 2px map.get($theme, color-greyscale-dark);
      outline          : none;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        transition : background-color 5000000s ease-in-out 0s;
      }
    }

    .ts_select1 {
      border-width  : 0 0 2px 0;
      border-radius : 0;

      &:focus {
        background-color : transparent;
      }
    }

    .text-uppercase {
      text-transform : uppercase !important;
    }

    .sidebar_menu_list .ts_sidebar_item_label {
      text-transform : uppercase;
    }

    #reclamo_section4 .form-group,
    .tesy_view_aclmanager .ts_modal_content .form-group {
      margin-bottom : map.get($theme, m5);
    }

    .tesy_view_assistenza .custom-form {
      .row {
        margin-bottom : map.get($theme, m5);
      }
      .custom-select  > div, .ts_textarea1 {
        border-width  : 0 0 2px 0;
        border-radius : 0;
        background-color : transparent;
        user-select: none;
        &:focus {
          background-color : transparent;
        }
      }
    }

    .page-standalone {
      background : map.get($theme, white);

      .company_logo {
        background-color : map.get($theme, black);
        padding          : map.get($theme, m2);
        min-width        : 20rem;
        margin           : 0 auto map.get($theme, m6);
      }

      .outlined_card, .otp_input input, .outlined_cta {
        border-radius : 0;
      }

      .outlined_card, .otp_input input {
        border : 1px solid map.get($theme, color-primary-light);
      }
    }

  }
}

