@use "sass:list";
@use 'sass:map';
@use '../theming' as *;

/*
  Classi generate: spazi  
*/

$modules: map.get($theme,m1) map.get($theme,m2) map.get($theme,m3) map.get($theme,m4) map.get($theme,m5) map.get($theme,m6) map.get($theme,m7) map.get($theme,m8) map.get($theme,m9) map.get($theme,m10);

@each $module in $modules {
  $i: list.index($modules, $module);
  .mb#{$i}, .ts_mb#{$i} {
    margin-bottom: #{$module} !important;
  }
  .mt#{$i}, .ts_mt#{$i} {
    margin-top: #{$module} !important;
  }
  .ml#{$i}, .ts_ml#{$i} {
    margin-left: #{$module} !important;
  }
  .mr#{$i}, .ts_mr#{$i} {
    margin-right: #{$module} !important;
  }
  .pb#{$i}, .ts_pb#{$i} {
    padding-bottom: #{$module} !important;
  }
  .pt#{$i}, .ts_pt#{$i} {
    padding-top: #{$module} !important;
  }
  .pl#{$i}, .ts_pl#{$i} {
    padding-left: #{$module} !important;
  }
  .pr#{$i}, .ts_pr#{$i} {
    padding-right: #{$module} !important;
  }
}

.mb0, .ts_mb0 {
  margin-bottom: 0 !important;
}

.mt0, .ts_mt0 {
  margin-top: 0 !important;
}

.ml0, .ts_ml0 {
  margin-left: 0 !important;
}

.mr0, .ts_mr0 {
  margin-right: 0 !important;
}

.pb0, .ts_pb0 {
  padding-bottom: 0 !important;
}

.pt0, .ts_pt0 {
  padding-top: 0 !important;
}

.pl0, .ts_pl0 {
  padding-left: 0 !important;
}

.pr0, .ts_pr0 {
  padding-right: 0 !important;
}

/*
  Classi generate: font  
*/

.t1 {
  font-size: map.get($theme,font1);
}

.t2 {
  font-size: map.get($theme,font2);
}

.t3 {
  font-size: map.get($theme,font3);
}

.t4 {
  font-size: map.get($theme,font4);
}

.t5 {
  font-size: map.get($theme,font5);
}

//$fontweights: map.get($theme, fontweight);
$fontweights: 100, 300, 500;
@each $weight in $fontweights {
  .t#{$weight} {
    font-weight: #{$weight};
  }
  .zazza-t#{$weight} {
    font-weight: #{$weight};
  }
}

/*
  Reset
*/
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
ol {
  color: map.get($theme,color-greyscale-darker);
  margin-bottom: 0;
}

/*
  Icone
*/

.icon_s {
  font-size: 0.75rem;
}

.icon_m {
  font-size: 1.5rem;
}

.icon_l {
  font-size: 2rem;
}

/** 
 View containers 
**/

.ts_viewcontainer {
  display: block;
  height: calc(100vh - 6rem);
  overflow-y: auto;
}

.ts_view .ts_viewcontent {
  display: block;

  .ts_viewcontent_margins {
    margin: 4rem 6rem;
  }
}

.ts_view_content {
  display: block;
  padding: map.get($theme,m6);
  overflow-y: auto;

  &.thread_container {
    background-color: white;

    .thread {
      height: 47vh;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}

.ts_data_table {
  position: relative;
  display: block;
  height: auto;
  overflow-y: hidden;
}

.ts_devexpress_table {
  overflow: visible !important;
  //padding-bottom: 10rem;
}

/*
  Struttura
*/

#root {
  width: 100%;
  overflow-x: hidden;
}

.ts_sidebar {
  transition: map.get($theme,speed-fast);
  overflow-x: hidden;
  background-color: map.get($theme,color-sidebar);
  z-index: 2;
  height: 100vh;
  overflow-x: visible;
}

.ts_body {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: map.get($theme,color-greyscale-light);
  transition: all 500ms 0ms cubic-bezier(0.86, 0, 0.07, 1);
}

.ts_content_row {
  height: 100vh;
  overflow: hidden;
}

.ts_content_header {
  padding: 2rem;
  align-items: center;
  display: flex;
  background-color: white;
  z-index: 50;
  position: relative;

  &.active {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  }
}

.ts_sidebar_header {
  transition: map.get($theme,speed-fast);
  display: flex;
  justify-content: center;
  padding: map.get($theme,m7) 0 map.get($theme,m7) 0;
  align-items: center;
  overflow: visible;
}

.ts_sidebar_header img {
  height: 11rem;
  width: 11rem;
  object-fit: contain;
  transition: map.get($theme,speed-fast);
  cursor: pointer;
}

.ts_sidebar_expanded {
  width: map.get($theme,sidebar-width) !important;
  max-width: map.get($theme,sidebar-width) !important;
}

.ts_sidebar_toggle i {
  transition: map.get($theme,speed-fast);
}

.ts_sidebar_minimized {
  max-width: 6rem !important;
  overflow-x: hidden;

  img {
    width: 4rem;
    height: 4rem;
  }
}

.statistiche_view {
  font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif;

  .chart-wrapper {
    margin-bottom: 2rem;
    border: 1px solid white;
    background-color: white;
    border-radius: 5px;
    padding: 5rem;

    .chart-info {
      margin-bottom: 2rem;

      .chart-title {
        display: flex;
      }

      .chart-select {
        margin-top: 1rem;
      }
    }
  }
}

.table_wrapper {
  border: 1px solid white;
  background-color: white;
  border-radius: 5px;
  padding: 3rem;
}

.ts_content_body .container-custom {
  padding-left: map.get($theme,m6);
  padding-right: map.get($theme,m6);
}

/* Tablet e smartphone */
@media screen and (max-width: 1024px) {
  .chart-info {
    margin-bottom: 2rem;
  }

  .statistiche_view {
    .chart-wrapper {
      padding: 2rem 1rem;
    }
  }

  .table_wrapper {
    border: none;
    border-radius: 0;
    padding: 0;
  }
}

@media screen and (max-width: 992px) {
  .chart-info {
    margin-bottom: 2rem;
  }

  .chart-select {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .logo-mobile {
    max-width: 46px !important;
    height: auto;
  }
  .ts_view_content {
    //padding: map.get($theme,m5) map.get($theme,m5) map.get($theme,m8) map.get($theme,m5);
    padding: map.get($theme,m5);
  }

  .ts_content_header {
    position: fixed;
    width: 100%;
    padding: 1rem 2rem;
  }

  .ts_content_body {
    &:not(._disable-navigation) {
      padding-top: 68px;
    }
  }
}

/* Solo Tablet */

@media screen and (max-width: 992px) and (min-width: 768px) {
}

/* Smartphone */

@media screen and (max-width: 767px) {
  .ts_content_body {
    margin-bottom: 10rem;
  }
}
