@use 'sass:map';
@use '../theming' as *;

.title1 {
  font-size: map.get($theme,font5);
  font-weight: 300;
}

.title2 {
  font-size: 3rem;
  font-weight: 300;
}

.title3 {
  font-size: 2rem;
  font-weight: 300;
}

.subtitle1 {
  font-size: map.get($theme,font1);
}

.subtitle2 {
  font-size: map.get($theme,font2);
}

.custom-placeholder {
  font-size: map.get($theme,font3);
  color: map.get($theme,color-primary-light);
}

.font-reset {
  font-size: 1.25rem !important;

  p,
  a,
  span,
  b,
  font,
  div {
    font-size: 1.25rem !important;
  }
}

.full-document {
  .rpv-core__inner-page {
    background-color: transparent !important;
  }
}
