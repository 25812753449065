//@use "sass:color";
@use 'sass:map';
@use '../theming' as *;

.pdf-viewer-wrapper {

	border:1px solid map.get($theme,color-greyscale-disabled);

	.pdf-viewer-toolbar {
		display: flex;
		justify-content: space-between;

		padding: map.get($theme,m1) map.get($theme,m4);

		font-size: map.get($theme,font2);
		border-bottom:1px solid map.get($theme,color-greyscale-disabled);
		background-color: map.get($theme,color-greyscale-light);

		button {
			border: 1px solid map.get($theme,color-greyscale-disabled);
			border-radius: map.get($theme,radius-small);
			cursor: pointer;
			background-color: map.get($theme,accent-white);
			min-width: map.get($theme,m5)*1.5;
			text-align: center;
		}

		.pdf-viewer-resize-buttons span {
			display: inline-block;
			min-width: map.get($theme,m7);
			text-align: center;
		}

		.pdf-viewer-download-button {
			display: inline-block;
			padding: 0 map.get($theme,m4);
			border: 1px solid map.get($theme,color-greyscale-disabled);
			border-radius: map.get($theme,radius-small);
			cursor: pointer;
			color: map.get($theme,accent-black);
			background-color: map.get($theme,accent-white);
			text-align: center;
			text-decoration: none;
		}

		.pdf-viewer-full-width {
			margin-left: map.get($theme,m2);
			cursor: pointer;
		}

		.pdf-viewer-close-button {
			margin-left: map.get($theme,m2);
		}
	}

	.pdf-viewer-inner {
		position: relative;
		z-index: 1;

		overflow-y: scroll;
		height: calc(100vh - 300px);

		&.is-modal {
			height: calc(100vh - 110px);
		}

		margin: 0 auto;

		background-color: map.get($theme,accent-white);

		.react-pdf__Page {
			z-index: 999 !important;
			background-color: transparent !important;

			canvas {
				margin: map.get($theme,m4)*1.25 auto;
				box-shadow: 0 0 map.get($theme,m2) rgba(map.get($theme,color-greyscale-darker),0.35);
			}
		}

		&.page-shadows {
			.react-pdf__Page canvas {
				margin: 0 auto;
				box-shadow: none;
			}
		}

		.react-pdf__message {
			&.react-pdf__message {
				&--no-data {
					padding: map.get($theme,m2);
					font-size: map.get($theme,font2);
				}

				&--loading {
					padding: map.get($theme,m2);
					font-size: map.get($theme,font2);
					color: map.get($theme,color-greyscale-disabled);
				}

				&--error {
					padding: map.get($theme,m2);
					font-size: map.get($theme,font2);
					color: map.get($theme,accent-red);
				}
			}
		}

	}
}

