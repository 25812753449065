@use 'sass:map';
@use '../theming' as *;
@use "mixins";

.page-standalone {
  position   : relative;
  min-height : 100vh;
  background : map.get($theme,accent-silver-rust);

  .company_logo {
    display: block;
    max-width: 100px;

    &.centered {
      margin: map.get($theme,m2) auto map.get($theme,m7);
    }
  }

  .public-lang-switcher-wrapper {
    position : absolute;
    top      : 2.5rem;
    right    : 2.5rem;

    .lang-switcher .lang-selected div {
      color      : black;
      padding    : 1.5rem 0;
      text-align : center;
    }
  }

  .error-msg {
    color : map.get($theme,accent-red);
  }

  .message-area {
    display: inline-block;
    width : 100%;
    border: 2px solid;
    padding: map.get($theme,m5);
    border-radius: map.get($theme,radius-small);

    &.message-info {
      border-color: map.get($theme,color-success-medium-alt);
      background-color: rgba(map.get($theme,color-success-medium-alt), 0.15);
    }

    &.message-warning {
      border-color: map.get($theme,color-orange-600);
      background-color: rgba(map.get($theme,color-orange-600), 0.15);
    }

    &.message-error {
      border-color: map.get($theme,accent-red);
      background-color: rgba(map.get($theme,accent-red), 0.1);
      .message-content {
        color: map.get($theme,accent-red);
        font-weight: 500;
      }
    }
  }

  .message_page {
    margin-top    : 5rem;
    margin-bottom : 15rem;
  }

  .limited-content-container {
    width     : 100%;
    max-width : 768px;
    padding   : 0 1rem 2.5rem;
  }

  &, div, h1, h2, h3, h4, h5, h6, p, ul, li, a, input, button {
    font-family : map.get($theme,app-standalone-pages-font);
    font-weight : 700;
    color       : map.get($theme,accent-black);
  }

  > * {
    margin-bottom : 1rem;
    line-height   : 1;
  }

  .text {
    font-size      : 2.4rem;
    letter-spacing : 0.1rem;

    &.upper {
      text-transform : uppercase;
    }

    &.normalized {
      line-height : 2.7rem;
      font-weight : normal;
    }

    &.smaller {
      font-size : 1.8rem;
    }
  }

  .title {
    font-size      : 4.8rem;

    .tesy_view_otp-aap & {
      font-size : 4rem;
    }

    letter-spacing : 0.1rem;
    text-transform : uppercase;

    &_main {
      font-size      : 6.4rem;

      .tesy_view_otp-aap & {
        font-size : 6rem;
      }

      letter-spacing : 0.2rem;
    }

    &_sub {
      font-size : 2.8rem;

      i.fas {
        margin-bottom : 20px;
        font-size     : 50px;
      }

      span a {
        text-decoration : underline;
      }

      small {
        display        : block;
        margin-top     : 3rem;
        text-transform : uppercase;
        font-size      : 1.2rem;
        letter-spacing : 1px;
        opacity        : 0.7;
      }
    }
  }

  .link {
    font-weight     : normal;
    text-decoration : underline;
    color           : map.get($theme,accent-dark-green);

    &.font-small {
      font-size : 0.7em;
    }
  }

  .buttons_wrapper {
    @include mixins.buttons_wrapper;

    &.spacer {
      &-top {
        margin-top : map.get($theme,m8);
      }
      &-bottom {
        margin-bottom : map.get($theme,m9);
      }
    }

    .buttons_inner {
      display         : flex;
      justify-content : center;
      @media screen and (max-width : 576px) {
        flex-direction : column;
        align-items    : center;
      }
    }
  }

  @include mixins.outlined_buttons;

  .margin_bottom {
    &_large {
      margin-bottom : 4rem;
    }

    &_narrow {
      margin-bottom : 2rem;
    }
  }

  .otp_input {
    margin-bottom : 3rem;

    input {
      width         : 75px !important;
      height        : 75px;
      border-radius : 10px;
      border        : none;
      font-size     : 40px;
      box-shadow    : inset -2px 2px 10px rgba(#000, .2);
      margin        : 0 13px;
      color         : #000;

      @media screen and (max-width : 767px) {
        width     : 50px !important;
        height    : 50px;
        margin    : 0 7px;
        font-size : 30px;
      }
    }
  }

  .otp_accept {
    @include mixins.clearfix;
    display       : flex;
    align-items   : flex-start;

    margin-bottom : 3rem;

    label {
      max-width   : 95%;
      margin-left : 5px;
      text-align  : left;
      font-size   : 14px;
      font-weight : normal;
      cursor      : pointer;
    }
  }

  #footer {
    display         : flex;
    margin-top      : 50px;
    margin-bottom   : 20px;
    justify-content : space-between;

    ul {
      padding-left : 0;
      list-style   : none;

      &:nth-child(1) {
        text-align : left;
      }

      &:nth-child(2) {
        text-align : center;
      }

      &:nth-child(3) {
        text-align : right;
      }

      li {
        display        : inline-block;
        text-align     : left;
        font-size      : 10px;
        font-weight    : normal;
        text-transform : uppercase;

        &.separtor {
          margin-left  : 6px;
          margin-right : 6px;
        }

        a:hover {
          color : map.get($theme,accent-dark-green);
        }
      }
    }
  }
}

.company_info_wrapper {
  font-size   : 14px;
  line-height : 22px;

  h3, p {
    margin-bottom : 20px;
  }

  h3 {
    font-size : 30px;
  }
}

.tesy_view_otp-co {
  padding-left : 0 !important;
}

@media screen and (max-width : 991px) {
  .page-standalone {
    .company_logo {
      max-width : 10rem;
    }

    .margin_bottom_large {
      margin-bottom : 2rem;
    }

    .margin_extended_mobile {
      margin-bottom : 3rem;
    }

    .text {
      font-size   : 1.6rem;
      line-height : 1.2;

      .tesy_view_otp-aap & {
        font-size      : 2rem;
        letter-spacing : 0.1rem;
      }
    }

    .title {
      font-size   : 3.2rem;
      line-height : 1;

      &_main {
        font-size : 5rem;
        margin    : 2rem 0;
      }
    }

    .outlined {
      &_card {
        padding : 2rem 3rem;
      }

      &_cta {
        height    : 45px;
        font-size : 2rem;
      }
    }
  }
}

@media screen and (max-width : 767px) {
  .tesy_view_otp-co {
    .ts_content_body {
      margin-bottom : 0;
    }
  }

  .page-standalone #footer {
    flex-direction : column;

    ul {
      text-align    : center !important;
      margin-bottom : 30px;
    }
  }
}
