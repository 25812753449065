$family            : 'D-DIN', 'D-DIN Bold', sans-serif;
$family-standalone : 'Helvetica Neue', sans-serif;

$typography        : (
        app-font: $family,
        app-standalone-pages-font: $family,
        font0: 1rem,
        font1: 1.2rem,
        font2: 1.5rem,
        font3: 2.1rem,
        font4: 2.6rem,
        font5: 4rem,
        font6: 6.6rem,
);

// if change family check also for $fontweights into modules
@font-face {
  font-family  : 'D-DIN';
  src          :  url('../fonts/D-Din-font/D-DIN.woff2') format('woff2'),
                  url('../fonts/D-Din-font/D-DIN.woff') format('woff'),
                  url('../fonts/D-Din-font/D-DIN.ttf') format('truetype');
  font-weight  : normal;
  font-style   : normal;
  font-display : swap;
}

@font-face {
  font-family  : 'D-DIN Bold';
  src          :  url('../fonts/D-Din-font/D-DIN-Bold.woff2') format('woff2'),
                  url('../fonts/D-Din-font/D-DIN-Bold.woff') format('woff'),
                  url('../fonts/D-Din-font/D-DIN-Bold.ttf') format('truetype');
  font-weight  : bold;
  font-style   : normal;
  font-display : swap;
}
