@use 'sass:map';
@use '../theming' as *;

/*
	Filtri visivi per variare il contrasto o i colori
*/

.ts_base {

	&.tesy_dark{
	    filter: invert(100%) contrast(70%) hue-rotate(180deg) saturate(70%);
	}

	&.tesy_contrast{
  		filter:  brightness(0.8) contrast(180%) saturate(140%);
	}

	&.tesy_sepia{
  		filter:  sepia(7%) contrast(95%);
	}

	&.tesy_delicate{
  		filter:  contrast(95%) saturate(90%);
	}

}

.cursor-pointer{
	cursor: pointer;
}


/*
	Adattamento layout carattere per device
	Il layout usa REM quindi modificando la font size viene scalato tutto il layout
*/

@media screen and (max-width: 992px) and (min-width: 768px){
  html{
    font-size: map.get($theme,app-size) * .9
  }
}

@media screen and (max-width: 767px) {
  html{
    font-size: map.get($theme,app-size) *.85;
  }
}
