

.progress-bar-wrapper {
	//padding-left: 5rem;
	//padding-right: 5rem;

	.progress-bar-line {
		height: 0.5rem;
		width: 100%;
		background-color: black;
	}

	.progress-bar-item-wrapper {
		display: flex;
		justify-content: center;

		.progress-bar-item-checkbox {
			height: 3rem;
			width: 3rem;
			//background-color: black;
			margin-top: -13px;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1px solid black;
		}

		.progress-bar-item-checkbox-small {
			height: 1.5rem;
			width: 1.5rem;
			//margin-top: -13px;
			//display: flex;
			//justify-content: center;
			//align-items: center;
			border: 1px solid black;
		}

		.progress-bar-item-label {
			position: absolute;
			margin-top: 21px;
		}
	}

	.progress-bar-item-wrapper:last-child {
		.progress-bar-item-label {
			//right: 15px;
		}
	}
}

@media screen and (max-width: 767px) {
	.progress-bar-wrapper {
		padding: 0;
		.progress-bar-item-label {
			display: none;
		}

	}
}
