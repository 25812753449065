@use 'sass:map';
@use '../theming' as *;

/** Notifications **/

.ts_notifications {
  position: fixed;
  top: 4rem;
  right: 2rem;
  z-index: 300;
}

.ts_notification_item {
  color: map.get($theme,color-greyscale-lighter);
  margin-top: .5rem;
  font-size: map.get($theme,font1);
  width: map.get($theme,notification-width);
  border-radius: map.get($theme,radius-medium);
  font-weight: 700;
  opacity: .9;

  &.success {
    background-color: map.get($theme,color-success-medium);
    border: solid 1px map.get($theme,color-success-medium);
    padding: 1.5rem 2rem;
  }

  &.regular {
    background-color: map.get($theme,color-greyscale-darker);
    border: solid 1px map.get($theme,color-greyscale-darker);
    padding: 1.5rem 2rem;
  }

  &.warning {
    background-color: map.get($theme,color-warning-medium);
    border: solid 1px map.get($theme,color-warning-medium);
    padding: 1.5rem 2rem;
  }

  &.error {
    background-color: map.get($theme,color-error-medium);
    border: solid 1px map.get($theme,color-error-medium);
    padding: 1.5rem 2rem;
  }

}
