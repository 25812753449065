@use 'sass:map';
@use '../theming' as *;

.dx-datagrid-focus-overlay {
    border: none;
}

.ts_devexpress_table .dx-datagrid-rowsview .dx-row.dx-group-row {
    color: black;
}

.ts_devexpress_table .dx-datagrid-filter-row .dx-texteditor-container {
    //border: solid 2px map.get($theme,color-greyscale-dark) !important;
    border: solid 0 map.get($theme,color-greyscale-dark) !important;
    border-radius: map.get($theme,radius-medium);
}

.ts_devexpress_table .dx-datagrid-filter-row .dx-menu {
    padding-left: .25rem;
}

.dx-datagrid .dx-row.ts_cell_alert td,
.dx-datagrid .dx-row-alt.ts_cell_alert td {
    background-color: map.get($theme,color-warning-medium);
    color: white;

    p, h2, a {
        color: white;
    }
}

.ts_devexpress_table_mini {
    background-color: map.get($theme,color-greyscale-light);
    padding: 1rem;
    border-radius: map.get($theme,radius-medium);
}

.ts_devexpress_table table {
    font-size: map.get($theme,font1);
}

.tesy_tablefontsize_1 .ts_devexpress_table table {
    font-size: .8rem;
}

.tesy_tablefontsize_2 .ts_devexpress_table table {
    font-size: map.get($theme,font1);
}

.tesy_tablefontsize_3 .ts_devexpress_table table {
    //font-size: map.get($theme,font2);
    font-size: 1.4rem;
}

.tesy_tablefontsize_4 .ts_devexpress_table table {
    font-size: map.get($theme,font3);
}

.tesy_tablefontsize_5 .ts_devexpress_table table {
    font-size: map.get($theme,font4);
}


.ts_devexpress_table {

    .ts_grid_cell_center {
        display: flex;
        top: 0;
        bottom: 0;
        align-items: center;
        position: absolute;
    }

    .dx-datagrid-header-panel {
        display: none;
    }

    .dx-datagrid-pager {
        border-top: solid 1px map.get($theme,color-greyscale-light);
        padding-right: 1rem;
        font-size: map.get($theme,font1);
    }

    .dx-page {
        font-size: map.get($theme,font0);
    }

    .dx-datagrid-nowrap .dx-header-row > td > .dx-datagrid-text-content {
        font-size: map.get($theme,font0);
        font-weight: 700;
        color: map.get($theme,color-greyscale-dark);

        &.dx-sort-indicator {
            color: map.get($theme,color-greyscale-darker);
        }
    }

    .dx-datagrid-headers {
        //border-top: none;
        //border-left: none;
        //border-right: none;
        //font-weight: bold;
        //background-color: #a0a0a0;
        //background-color: white;
        color: black;
        //border-top: none;
        tr td {
            height: 5rem;
            //border-left: none;
            //border-right: none;
        }
    }

    .dx-datagrid-rowsview {
        border-top: none;
        tr td {
            position: relative;
            vertical-align: middle;
            height: 5rem;
        }
    }

    .dx-header-row td {
        border-bottom: none !important;
        position: relative;
        overflow: visible;

        /*&:not(:first-of-type):hover:after {
            position: absolute;
            height: 2rem;
            display: block;
            top: 1rem;
            left: -1.5rem;
            height: 2rem;
            display: block;
            width: 2rem;
            content: "\e259";
            font-family: 'icomoon';
            transform: rotate(90deg);
            z-index: 1;
            font-size: 1rem;
            color: map.get($theme,color-greyscale-darker);
        }*/

        .dx-header-row td {
        }
    }

    .dx-datagrid-filter-row {
        border: none;

        .dx-editor-with-menu {
            margin: 0 0 2rem 0;
        }

        .dx-texteditor-input {
            //border: none;
            padding-top: .5rem !important;
            padding-bottom: .5rem !important;
            height: 3rem !important;
            min-height: 0;
        }
    }

    .dx-datagrid-content tr td:first-child {
        //padding-left: 2rem;
    }

    .dx-datagrid-content tr td:last-child {
        //padding-right: 2rem;
    }

    .dx-editor-cell:first-child {
        padding-left: 2rem !important;
    }

    .dx-editor-cell {
        padding-left: .7rem !important;
        padding-right: .7rem !important;
    }

    .dx-datagrid-filter-row .dx-editor-cell {
        vertical-align: top !important;
    }

    .dx-header-row .dx-select-checkbox {
        /*transform: translateY(3rem);*/
    }

    .dx-datagrid-group-closed, .dx-datagrid-group-opened {
        font-size: 3rem;
    }

    .icon-error .fa {
        color: map.get($theme,color-error-medium);
    }

    .icon-confirm .fa {
        color: map.get($theme,color-greyscale-darker);
    }

    .icon-done .fa {
        color: map.get($theme,color-success-medium)_alt;
    }

    .icon-error, .icon-confirm, .icon-done {
        .fa { cursor: help; }
    }

    .dx-master-detail-cell {
        .ts_gridoptions_wrapper {
            position: static;
        }

        .ts_cell_alert td {
            background-color: transparent;
            color: inherit;
        }

        .dx-adaptive-item-text {
            padding: 0 !important;
        }
    }
}


.ts_table_head {
    border: solid 2px map.get($theme,color-greyscale-medium);
    background-color: map.get($theme,color-greyscale-lighter);
    padding: 1rem 0;
    font-size: map.get($theme,font1);
    height: 5rem;
    color: map.get($theme,color-greyscale-dark);
    align-items: center;
    text-transform: uppercase;

    .ts_table_sort {
        width: .8rem;
        height: auto;
        opacity: .7;
        display: inline-block;
        margin-left: .5rem;
        cursor: pointer;
    }
}

.ts_table_row {
    //border-bottom: solid 1px map.get($theme,color-greyscale-medium);
    padding: 1.5rem 0;
    font-size: map.get($theme,font1);
    transition: map.get($theme,speed-fast);
    cursor: pointer;
    transition: map.get($theme,speed-fast);

    &.active,
    &.active:hover {
        background-color: map.get($theme,color-primary-medium);
        color: white;

        img {
            filter: invert(100%);
        }
    }

    div {
        align-items: center;
    }

    img {
        height: map.get($theme,font2);
        width: auto;
        margin-right: 1rem;
    }

    &:hover {
        background-color: map.get($theme,color-primary-lighter);
    }
}

.order-detail-table {
    .dx-datagrid-headers {
        //background-color: #f7f7f7;
    }
}

.topnordini_table {
    .dx-datagrid-headers {
        background-color: #536d81;
        border-bottom: none;
    }
}

.dx-scrollable-container,
.dx-scrollable-native.dx-scrollable-container {
    height: auto !important;
}


/*
// TODO Decide whether to apply icon to shoq that the table continues beyond the viewport
$magic_size: 50px;
.dx-scrollable-wrapper {
    position : relative;
    &::before {
        content          : '\2794';
        position         : absolute;
        top              : calc($magic_size/2);
        right            : calc($magic_size/2);
        font-size        : map.get($theme,font4);
        color            : white;
        background-color : map.get($theme,accent-dark-green);
        animation        : fadeIn 2s forwards 3, slide 2s forwards 3;
        z-index          : 999;
        display          : block;
        width            : $magic_size;
        height           : $magic_size;
        line-height      : $magic_size;
        text-align       : center;
        vertical-align   : middle;
    }
}

@keyframes slide {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100px);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    30%, 100% {
        opacity: 1;
    }
}
*/




.ts_data_table_options {
    overflow-x: hidden;
}

.ts_data_checkbox_filter {
    min-height: 40px;

    .dx-checkbox-text {
        padding-left: 10px;
    }
}

.dx-datagrid {
    background-color: transparent;
}

.dx-filter-menu.dx-menu .dx-menu-item .dx-menu-item-content {
    padding-top: 7px;
}

.dx-widget input, .dx-widget textarea {
    font-family: map.get($theme,app-font) !important;
}
.form-assistenza {
    .dx-texteditor-input {
        width: 100%;
    }
}

/* Tablet e smartphone */

@media screen and (max-width: 992px) {
    .dx-filter-menu.dx-menu .dx-menu-item .dx-menu-item-content {
        padding-top: 4px;
    }
}
