@use 'sass:map';
@use '../theming' as *;
@use "mixins";

.commerciale-wrapper {

  .page-title {

    $font_size: map.get($theme,font1) * 1.083;
    .pre-title {
      display        : inline-block;
      margin-bottom  : map.get($theme,m1);
      font-size      : $font_size;
      font-weight    : 300;
      letter-spacing : 0.05rem;

      span {
        font-size : $font_size;
      }
    }

    .title {
      font-size      : map.get($theme,font3);
      font-weight    : 700;
      letter-spacing : 0.125rem;

      em {
        display     : block;
        font-size   : map.get($theme,font2);
        font-style  : normal;
        font-weight : normal;
      }

      span {
        font-size      : $font_size;
        font-weight    : 300;
        color          : map.get($theme,color-greyscale-disabled);
        letter-spacing : 0;
      }

      .change-client .svg-ico {
        width : 16px;
      }
    }

    .change-client {
      display        : inline-block;
      margin-left    : map.get($theme,m1);
      cursor         : pointer;
      .svg-ico {
        width: 12px;
      }
    }

    .requested-by {
      font-weight: 300;
      font-size: $font_size;
    }
  }

  @media only screen and (max-width : 570px) {
    .commerciale-header-wrapper {
      flex-direction : column-reverse;

      .commerciale-cart-icon-wrapper {
        max-width : unset !important;
      }
    }
  }

  .commerciale-cart-icon-wrapper {
    button {
      @include mixins.cart-icon-wrapper-button;

      div.cart-label {
        float       : none;
        margin-left : -50%;
      }
    }

    .back-to-list {
      cursor: pointer;

      span {
        display: inline-block;
        margin-right: map.get($theme,m2);
        color: map.get($theme,accent-black);
        font-size: map.get($theme,font2);
        font-style: normal;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
        white-space: nowrap;
      }
    }
  }

  .content-list {

    .product-filtered-wrapper {
      .select-model {
        min-width: 18%;
        margin-right: 2%;
      }

      .product-search {
        flex: auto;
        max-width: 38%;
        margin-right: 2%;
      }

      .product-sorter {
        min-width: 18%;
        margin-right: 2%;

        .sort_filter {
          flex-direction  : row;
          align-self      : flex-end;
          justify-content : flex-start;
          width           : 100%;

          .sort_by_select {
            width : 100%;
          }
        }
      }

      .sort_button {
        .order_by_asc {
          transform : scaleY(-1);
        }
      }

      .cta {
        @include mixins.apply_and_clear_ctas;
      }

      @media screen and (max-width : 1700px) {
        .select-model {
          min-width : 23%;
        }
        .product-search {
          min-width : 48%;
        }
        .product-sorter {
          min-width    : 21%;
          margin-right : 1%;
        }
      }

      @media screen and (max-width : 1172px) {
        .product-search {
          min-width : 64%;
        }
      }

      @media screen and (max-width : 640px) {
        flex-direction: column;
        .select-model,
        .product-search,
        .product-sorter {
          width: 100%;
          max-width: unset;
          margin-bottom : map.get($theme,m2);
        }
      }

    }

    .product-count {
      font-size  : map.get($theme,font2);
    }

    .product-card {
      $base_margin    : 2%;
      position         : relative;
      width            : 18%;
      min-width        : 250px;
      margin-right     : 2%;
      margin-bottom    : 2% !important;

      border           : 0;
      border-radius    : map.get($theme,radius-small);
      background-color : map.get($theme,accent-white);

      &:nth-child(5n) {
        margin-right : 0;
      }

      @media screen and (max-width : 1700px) {
        width  : 23%;
        &:nth-child(4n) {
          margin-right : 0;
        }
        &:nth-child(5n) {
          margin-right : $base_margin;
        }
      }

      @media screen and (max-width : 1172px) {
        width : 31%;
        &:nth-child(3n) {
          margin-right : 0;
        }
        &:nth-child(4n) {
          margin-right : $base_margin;
        }
      }

      @media screen and (max-width : 960px) {
        width : 48%;
        &:nth-child(2n) {
          margin-right : 0;
        }
        &:nth-child(3n),
        &:nth-child(4n) {
          margin-right : $base_margin;
        }
      }

      @media screen and (max-width : 575px) {
        width : 100%;
        margin-right : 0;
      }

      .sidebar-open & {
        @media screen and (max-width : 1400px) {
          min-width : 170px;
        }
      }

      .card-row {
        width: 100%;
      }

      .title {
        color          : map.get($theme,accent-black);
        font-size      : map.get($theme,font2);
        font-weight    : 700;
        letter-spacing : 0.0625rem;
        cursor         : pointer;
      }

      .preview {
        width               : 100%;
        min-height          : 260px;

        cursor              : pointer;

        background-position : center center;
        background-size     : contain;
        background-repeat   : no-repeat;

        &.loading {
          background-size     : auto;
        }

        img {
          visibility : hidden;
          width      : 100%;
        }
      }

      .product-footer {
        .price {
          @include mixins.price;
        }
        .product-card-cta .button {
          color:map.get($theme,accent-white);
        }
      }

      // used for CAL product
      &.extended {
        .title {
          margin-bottom: 0 !important;
        }
        .preview {
          min-height: 250px;
        }
        .content ul {
          list-style: none;
          padding: 0;
        }
      }
    }

    .contract-card {
      position         : relative;
      margin-bottom    : 2% !important;

      overflow-x       : hidden;

      border           : 0;
      border-radius    : map.get($theme,radius-small);
      background-color : map.get($theme,accent-white);

      .contract-card-shadow {
        position   : absolute;
        top        : 0;
        display    : block;
        width      : 100%;
        height     : 100%;
        background : rgb(255, 255, 255);
        background : linear-gradient(90deg, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 1) 90%);
      }

      .title {
        z-index        : 1;
        color          : map.get($theme,accent-black);
        font-size      : map.get($theme,font3);
        font-weight    : 700;
        letter-spacing : 0.0625rem;

        span {
          display              : inline-block;
          margin-left          : map.get($theme,m3);
          color                : map.get($theme,color-greyscale-dark);
          font-size            : map.get($theme,font1);
          font-style           : normal;
          font-weight          : 300;
          letter-spacing       : 1px;
          text-decoration-line : underline;
          cursor               : pointer;
        }

        strong {
          display              : inline-block;
          margin-left          : map.get($theme,m2);
          font-size            : map.get($theme,font2);
          font-weight          : normal;
          color                : map.get($theme,accent-red);
        }
      }

      .subtitle p{
        display:inline-block;
        &:first-child {
          margin-right: map.get($theme,m4);
        }
      }

      .contract-products {
        list-style   : none;
        padding-left : 0;

        li {
          width        : 170px;
          margin-right : map.get($theme,m7);
          text-align   : center;

          .preview {
            width               : 130px;
            height              : 130px;
            margin-right        : auto;
            margin-left         : auto;
            background-size     : contain;
            background-repeat   : no-repeat;
            background-position : center;

            img {
              display : none;
            }
          }

          strong {
            display    : block;
            text-align : center;
            font-size  : map.get($theme,font1);
          }
        }
      }

      .select-contract-wrapper {
        z-index: 1;
        position: absolute;
        top: map.get($theme,m2);
        right: map.get($theme,m2);
      }
    }

    .ts_data_table {
      .client-data {
        font-weight: normal;

        span {
          display: block;
          font-size: map.get($theme,font0);
          font-weight: 300;
        }
      }
    }

  }
}

.product-modal-wrapper {
  .image-wrapper .image-note {
    margin-bottom: 0;
    font-size: map.get($theme,font0);
    line-height: map.get($theme,font0) * 1.2;
  }

  .image {
    //max-width : 450px;
    width: 350px;
    min-height: 350px;

    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;

    img {
      display: none;
    }
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;

    padding-right : 1rem;

    h1 {
      margin-top     : 0.5rem;
      color          : map.get($theme,accent-black);
      font-size      : map.get($theme,font3);
      font-weight    : 700;
      letter-spacing : 0.0625rem;
    }

    h2 {
      color       : rgba(map.get($theme,accent-black), 0.60);
      font-size   : map.get($theme,font2);
      font-weight : 400;
    }

    .content ul {
      padding-left: 0;
      list-style-position: inside;
      font-size: map.get($theme,font1);
      margin-bottom: map.get($theme,m5);
    }

    .price {
      margin-bottom : 0;
      font-size     : map.get($theme,font3);
      font-weight   : 500;

      @include mixins.price;
    }

    .product-add-remove {
      margin-right : 0;
    }
  }

  &.ts_modal_content_large {
    @media screen and (max-width: 557px) {
        flex-direction: column !important;
        justify-content: center;
        align-items: center;

        .image-wrapper {
          width: 100% !important;
        }

        .image {
          width: 100% !important;
        }

        .content-wrapper {
          width: 100% !important;
        }
    }
  }
}

// TODO pay attention: code snippets inherited from Alessio Libbi, could be optimized
@media screen and (max-width : 768px) {
  .content-wrapper .cart-wrapper .product-card-wrapper .product-card .item-total-price-wrapper {
    width           : 40%;
    justify-content : space-around !important;
    flex-direction  : column;
    margin          : 10px;

    & .unit-price-qty-wrapper {
      width           : 100%;
      justify-content : flex-end !important;

      .price-wrapper {
        margin : 10px;
      }
    }
  }
}

@media screen and (max-width : 524px) {
  div.commerciale-header {
    flex-direction : column-reverse !important;
    align-items    : center;

    .page-title {
      align-self : flex-start;
    }
  }

  .commerciale-cart-icon-wrapper {
    min-width : 100% !important;


    div.back-to-list {

      margin-bottom : 10px;
      align-items   : flex-end;


      img.svg-ico {
        width : 20px;
      }
    }
  }
}

@media screen and (max-width : 560px) {
  .product-filtered-wrapper {
    flex-direction : column-reverse;
  }

  .commerciale-wrapper.content-wrapper .ts_data_table {
    .cart-wrapper {


      div .product-card-wrapper .product-card {
        width : 85%;

        .card-row {
          flex-direction : column;

          .item-wrapper {
            flex-direction : column;
            width          : 100%;

            .preview {
              margin-bottom : map.get($theme,m4);
              margin-right  : 0;
            }

            .item-title {
              width         : 100%;
              margin-bottom : map.get($theme,m4);
              text-align    : left;
            }
          }

          .unit-price-qty-wrapper {
            width           : 100%;
            justify-content : end !important;

            .price-wrapper {
              margin : 10px;
            }
          }

          .item-total-price-wrapper {
            width           : 100%;
            justify-content : center !important;

            .price-wrapper {
              text-align : right;
            }
          }
        }
      }

      .remove-item {
        width     : 15%;
        flex-grow : 1;
      }

      div .product-total-price {
        span {
          padding : 30px;
        }

        strong {
          width        : 300px;
          margin-right : 30px;
        }
      }

      .cart-additional-info {
        padding : 4rem 5rem;

        .datepicker-order-cause-wrapper {
          flex-direction : column !important;

          .delivery-date,
          .other-params {
            min-width     : inherit;
            width         : 100%;
            margin-top    : 10px;
            margin-bottom : 10px;
          }
        }
      }

      .cart-confirm-refuse-order {
        flex-direction : column-reverse;

        button.ts_buttoninline1.button {
          width         : 100% !important;
          margin-bottom : 20px !important;
          flex-grow     : 1;
        }
      }
    }
  }
}

@media only screen and (max-width : 766px) {
  .cart-additional-info {
    padding : 4rem 5rem;

    .datepicker-order-cause-wrapper {
      flex-direction : column !important;

      .delivery-date,
      .other-params {
        min-width     : inherit;
        width         : 100%;
        margin-top    : 10px;
        margin-bottom : 10px;
      }
    }
  }

  .cart-confirm-refuse-order {
    flex-direction : column-reverse;
    align-items    : center;

    button.ts_buttoninline1.button {
      width         : 80% !important;

      margin        : 0 !important;
      margin-bottom : 20px !important;
    }
  }
}

@media only screen and (max-width : 1100px) {
  .cart-additional-info {
    padding : 4rem 5rem;

    .datepicker-order-cause-wrapper {
      flex-direction : column !important;

      .delivery-date,
      .other-params {
        min-width     : inherit;
        width         : 100%;
        margin-top    : 10px;
        margin-bottom : 10px;
      }
    }
  }
}

.commerciale-wrapper.content-wrapper .content-list .contract-card .card-row {
  @media only screen and (max-width: 1200px) {
    .title {
      span,
      strong {
        display     : block;
        margin      : 10px 0 0 0;
        white-space : normal;
      }
    }
  }

  @media only screen and (max-width: 640px) {
    .select-contract-wrapper {
      position        : relative;
      display         : flex;
      flex-direction  : column;
      justify-content : center;
      .ts_buttoninline1 {
        width : 270px !important;
      }
    }
  }
}
