@use 'helpers';
@use 'sass:color';
@use 'sass:map';
@use '../theming' as *;

div.error403 {
  position: absolute;
  top: 50%;
  /* left: 50%; */
  width: 100%;
  transform: translateY(-50%);

  h2 {
    font-size: 12rem !important;
    font-weight: bolder !important;
    text-shadow: 5px 5px 0 #ffffff;
  }

  h4 {
    font-size: 3rem !important;
    text-decoration: underline;
  }

}

div.wip {
  position: absolute;
  top: 50%;
  /* left: 50%; */
  width: 100%;
  transform: translateY(-50%);
  background-color: map.get($theme,wip-background);

  h2 {
    font-size: 12rem !important;
    font-weight: bolder !important;
    color: helpers.set-text-color(map.get($theme,wip-background));
  }

  h4 {
    font-size: 3rem !important;
    text-decoration: underline;
    color: helpers.set-text-color(map.get($theme,wip-background));
  }

}

@media all and (max-width: 960px) {
  div.error403, div.wip {

    position: relative;
    transform: none;
    width: auto;

    h2 {
      font-size: 5rem !important;
    }
  }
}
