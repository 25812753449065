@use 'sass:map';
@use '../theming' as *;
@use "mixins";

html {
    font-size: map.get($theme,app-size);
}

body {
    font-family: map.get($theme,app-font);
}

.table-reset {
    th, td {
        font-size: 1.25rem !important;
        vertical-align: middle !important;
    }
}

.dx-datagrid-headers {
    .dx-datagrid-table {
        .dx-row {
            td {
                vertical-align: middle;
                display: table-cell;

                .dx-datagrid-text-content {
                    vertical-align: middle;
                    display: table-cell;
                    line-height: 1;
                    //font-weight: normal;
                }

                .dx-column-indicators {
                    vertical-align: middle;
                    display: table-cell;
                    margin-left: 3px;
                }
            }
        }
    }
}
.ts_devexpress_table {
    .dx-datagrid-rowsview {
        .dx-datagrid-table {
            .dx-row {
                td {
                    vertical-align: middle;
                }

                &:hover {
                    td {
                        //background: #ccc !important;
                        background: #ccc;
                    }
                }
            }
        }
    }
}

.ts_devexpress_table {
    .dx-header-row, .dx-datagrid-filter-row {
        background-color: #cfcfcf;
    }

    .dx-datagrid-filter-row {
        td:not(:first-of-type):hover:after {
            content: '' !important;
        }
    }

    .tesy_row_format_currency .gratis {
        color: map.get($theme, color-success-medium-alt);
        letter-spacing: 1px;
        text-transform: uppercase;
        font-size: map.get($theme, font1);
        font-weight: bold;
    }
}

.dx-datagrid-filter-row .dx-menu {
    margin-top: 0;
}


.dx-datagrid-filter-row {
    .dx-menu-item-has-submenu {
        background-color: transparent;

        &.dx-menu-item-expanded {
            background-color: transparent;

            &.dx-state-hover {
                background-color: transparent;
            }
        }

        &.dx-state-hover {
            background-color: transparent;
        }
    }
}

.dx-context-menu {
    .dx-context-menu-content-delimiter {
        background-color: transparent;
    }
}

.dx-context-menu-container-border {
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.logo-mobile {
    max-width: 46px !important;
    height: auto;
}

.mobile-app-nav {
    z-index: 9999999;
}

.full-link {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.contentBlockWrapper {
    display: none !important;
}

.table {
    th, td {
        vertical-align: middle;
    }
}

.multiselect {
    z-index: 9999 !important;
}

.userImpersonationLink {
    &:hover {
        background: #eeeeee;
    }
}

.a3-breadcrumbs {
    padding-left: 2px;
    font-size: 1.2rem;
}

video {
    width: 100%;
}

input.pin-control {
    height: 45px;
    border: 2px solid #333333;
    font-size: 25px;
    border-radius: 10px;
    text-align: center;
}

.black-checkbox{
    accent-color: black; /* Imposta il colore del checkbox su nero */
}

.lista-utenti-wrapper {
    .status- {
        &disabled, &blocked, &cancelled {
            font-weight : bold;
            color: map.get($theme,accent-red);
        }
    }
    .data-alert {
        font-weight : bold;
        color: map.get($theme,accent-red);
    }
    .cta {
        @include mixins.apply_and_clear_ctas;
    }

    .stats {
        flex-direction : row;
        @media screen and (max-width : 767px) {
            flex-direction: column;
        }
    }

    .filters {
        .search-by {
            flex: auto;
            max-width: 38%;
            margin-right: 2%;
        }

        .status {
            min-width: 18%;
            margin-right: 2%;
        }

        .sort-by {
            min-width: 18%;
            margin-right: 2%;

            .sort_filter {
                flex-direction  : row;
                align-self      : flex-end;
                justify-content : flex-start;
                width           : 100%;

                .sort_by_select {
                    width : 100%;
                }
            }
        }

        .sort_button {
            .order_by_asc {
                transform : scaleY(-1);
            }
        }

    }
}
