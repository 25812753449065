@use 'sass:map';
@use '../../theming' as *;

[data-hover-title] {
  position: relative;
  cursor: help !important;

  $timing_transition: .3s;
  $timing_delay: .75s;
  $offset_left: 0;
  $before_offset_bottom: -45px;
  $after_offset_left: -5px;

  &:before { // main tooltip
    content:attr(data-hover-title);
    position: absolute;
    pointer-events: none;
    user-select: none;

    height: fit-content;
    padding: 10px 15px;
    border-radius: 5px;
    background: #000;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
    z-index: 9999;
    box-shadow: 0px 0px 5px 0px rgba(#000, 0.3);

    transform: translateY(-100%);
    transition: opacity $timing_transition, bottom $timing_transition;
    opacity: 0;
    top: 0;
    left: ( -1 * $offset_left );
  }

  &:hover:before {
    transition-delay: $timing_delay;
    opacity: 1;
    bottom: $before_offset_bottom;
  }

  &:after { // arrow
    content: '';
    position: absolute;
    color: #fff;
    border: 8px solid transparent;
    border-top: 8px solid #000;
    z-index: 9999;

    transition: opacity $timing_transition, bottom $timing_transition;
    opacity: 0;
    top: ( $after_offset_left * 3 );
    left: $offset_left;
  }

  &:hover::after {
    transition-delay: $timing_delay;
    opacity: 1;
    top: 0px;
    left: 10%;
  }
}


.btn {
  border-radius: 0.6rem;
  font-size: 1.4rem;
  transition: 0.3s;
  font-weight: 700;
  align-items: center;
  outline: none !important;
  box-shadow: none !important;

  &:hover {
    background: transparent !important;
  }

  &.btn-primary {
    background: map.get($theme,color-greyscale-dark) !important;
    border-color: map.get($theme,color-greyscale-dark) !important;
  }

  &.btn-charging {
    background: map.get($theme,color-yellow-300) !important;
    border-color:  map.get($theme,color-yellow-300) !important;
  }

  &.btn-danger {
    background: map.get($theme,color-warning-medium) !important;
    border-color: map.get($theme,color-warning-medium) !important;
  }

  &.btn-block {
    display : block;
    width   : 100%;
  }
}

.rounded {
  overflow: hidden;
}

.dx-widget {
  font-family: map.get($theme,app-font);
}

.slider-slide > img {
  margin: auto !important;
}

.dx-popup-content {
  overflow: auto !important;
  height: auto !important;

  .dx-scrollable-content {
    max-height: 256px !important;
  }
}

.dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-container {
  margin-bottom: 20px;
}

.highlightOption {
  background-color: inherit;
  color: inherit;
}

.table {
  .actions {
    min-width: 120px;
  }
  .user_disabled td:not(:last-child) {
    opacity: 0.3;
  }
}

.badge {
  font-weight: normal;
  &.badge-disable { // "Non disponibile"
    color: #969696;
    background-color: #e1e3e4;
  }
  &.badge-engage { // "Da attivare"
    color: white;
    background-color: #849cb8;
  }
  &.badge-wip { // "Richiesta", "In attivazione"
    color: grey;
    background-color: #ede4d0;
  }
  &.badge-done { // "Attivata"
    color: grey;
    background-color: #cff1cc;
  }
}

.fancybox__caption {
  font-size: 30px;
}

.table.table_riparatori {
  th, td {
    width: 100px;
  }
}

.dx-row-total{
  background: #78B6D9 !important;
  color: white !important;
}

.dx-total.dx-row-total{
  background: #98bfd5 !important;
  color: white !important;
}

.tesy_view_ {
  &clienti-dettaglio {
    .badge-light {
      color: map.get($theme, color-greyscale-black);
      background-color: map.get($theme, color-greyscale-lighter);
    }
  }
}

.dx-datagrid-nodata {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-51%, 50%);
}

/*
.dx-last-cell{
  border-width: 4px !important;
}*/
