@use 'sass:map';
@use '../theming' as *;

.data_table_options_minified {
    padding: 1rem 2rem;
    display: flex;
}

.data_table_options_content {
    padding: 2rem 2rem 0 2rem;
    position: relative;
    font-size: map.get($theme,font1);

    .dx-dropdownbox, .dx-texteditor {
        height: map.get($theme,m6) !important;
        border-radius: map.get($theme,radius-medium);
        border: solid 2px map.get($theme,color-greyscale-dark);
    }

    //input[type="text"] {
    //    height: map.get($theme,m6) !important;
    //    min-height: map.get($theme,m6) !important;
    //    padding-top: 4px;
    //    padding-bottom: 4px;
    //}

    input[type="text"].ts_input2 {
        height: 2.9rem !important;
        min-height: 2.9rem !important;
    }

    .dx-placeholder {
        line-height: 1rem;
    }

    .dx-datebox {
        height: map.get($theme,m6) !important;
        border: solid 2px map.get($theme,color-greyscale-dark);
    }

    .dx-datebox input {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        height: calc(#{map.get($theme,m6)} - 4px) !important;
    }

    .dx-texteditor-input {
        min-height: 0;
    }

    h2, h3, h4, h5 {
        margin-top: 0 !important;
    }

    > .row > * {
        margin-top: 2rem;
    }

    &._variant {
        padding-top: 0;
    }

    &._filters {
        height: auto;
        max-height: 2000px;
        transition: 0.6s ease-in-out;
        overflow: hidden;

        &:not(.active){
            max-height: 0px;
        }
    }

    .clear_filters {
        border: solid 2px #2A2A2A;
        border-radius: 4px;
    }

    .sort_filter {
        flex-direction: row;
        align-self: flex-end;
        justify-content: flex-start;
        width: 100%;

        .sort_by_select {
            width: 90%;
        }
        .sort_button {
            margin-left: 5px;
            .order_by_asc {
                transform: scaleY(-1);
            }
        }
    }
}

.ts_data_table_options {

    &._toggle {
        .data_table_options_content {
            padding: 2rem 2rem 0 2rem;

            > div {
                width: 100%;
                border-radius: 4px;
                background: black;
                color: #fff;
                border: 2px solid #000;
            }

            .toggler_indicator {
                transform: rotate(0deg);
                transition: 0.3s ease-in-out;
            }

            &:hover {
                > div {
                    background: rgb(51, 51, 51);
                }
            }
        }

        &.active {

            .toggler_indicator {
                transform: rotate(-180deg);
            }
        }
    }
}

.ts_data_table {
    position: relative;
    display: block;
    height: auto;
    overflow-y: hidden;
}

.ts_data_table_options_toggle {
    padding: 1rem;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;

    i {
        font-size: 2rem;
    }
}


@media screen and (max-width: 767px) {

    .data_table_options_content {
        > .row > * {
            margin-top: 1.5rem !important;
        }
    }

    .ts_button1 {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        display: flex;
        justify-content: center;
        margin-bottom: map.get($theme,m2);
    }

    .ts_buttoninline1 {
        width: 100%;
        display: flex;
        justify-content: center;
        border: 2px solid map.get($theme,color-greyscale-dark);
        border-radius: map.get($theme,radius-medium);
        //border-color: map.get($theme,color-greyscale-dark);
    }

    .data_table_options_minified {
        display: block;
    }

    // TODO sass migration error suppression
    //&:not(._toggle)
    //{
    //    .table_options_header_btn {
    //        margin: 0 -5px;
    //        .ts_buttoninline1 {
    //            margin: 0 5px;
    //        }
    //    }
    //}

    .data_table_options_content {

        h2, h3, h4, h5 {
            margin-top: 0.5rem !important;
            margin-bottom: 0.1rem !important;
        }

        .row {
            padding: 0 10px;
            > * {
                padding: 0 5px;
                margin: 0;
            }
        }
    }

    .table_options_header_btn {
        margin-top: 0;
        padding: 1rem 2rem 0 2rem;
    }

    .table_actions {
        padding: 0 10px !important;
        > * > * {
            display: flex;
            flex-direction: column;

            & > * {
                margin: .5rem 0 !important;
            }
        }
    }
}


@media screen and (max-width: 575px) {
    .table_options_header_btn {
        .ts_buttoninline1 {
            &._sm_icon_only {
                max-width: 50px;

                i {
                    margin-right: 0;
                }
            }
        }
    }
}
