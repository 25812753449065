@use 'sass:map';
@use '../theming' as *;
@use "mixins";

/** Modals **/

.ts_modal_wrap {
  z-index         : 99999;
  position        : fixed;
  display         : flex;
  align-items     : center;
  justify-content : center;
  width           : 100%;
  height          : 100%;
  top             : 0;
  left            : 0;
  overflow        : auto;
}

.ts_modal_back {
  position         : fixed;
  width            : 100%;
  height           : 100%;
  top              : 0;
  left             : 0;
  background-color : rgba(0, 0, 0, .7);
}

.ts_modal_frame {
  position         : relative;
  width            : auto;
  height           : auto;
  background-color : white;
  box-shadow       : 0 0 10px map.get($theme,color-greyscale-darker);
  border-radius    : map.get($theme,radius-small);
}

.ts_modal_frame_fullscreen {
  position         : relative;
  width            : 80%;
  height           : 95%;
  //height: auto;
  background-color : white;
  box-shadow       : 0 0 10px map.get($theme,color-greyscale-darker);
  border-radius    : map.get($theme,radius-small);
}

.ts_modal_frame_video {
  position         : relative;
  width            : auto;
  max-width        : 70%;
  height           : auto;
  background-color : white;
  box-shadow       : 0 0 10px map.get($theme,color-greyscale-darker);
  border-radius    : map.get($theme,radius-small);
}

.ts_modal_content {
  position : relative;
  width    : 100%;
  height   : 100%;

  .buttons_wrapper {
    @include mixins.buttons_wrapper;
  }

  @include mixins.outlined_buttons;

  .max_chars_limit {
    display     : inline-block;
    margin-left : 1rem;
    color       : map.get($theme,accent-red);
  }

  &.default-text {
    h1 {
      margin-bottom : map.get($theme,m3);
    }

    p, li {
      margin-bottom : map.get($theme,m3);
      font-size     : map.get($theme,font2);
      line-height   : map.get($theme,font3);
    }

    ul.list {
      padding    : 0;
      list-style : none;
    }

    .small {
      font-size : map.get($theme,font1);
    }

    .medium {
      font-size : map.get($theme,font3);
    }

    .big {
      font-size : map.get($theme,font5);
    }

    .highlighted-text {
      margin-top    : map.get($theme,m6);
      margin-bottom : map.get($theme,m6);
      text-align    : center;
      font-size     : map.get($theme,font3);
    }

    .hr {
      border-bottom: 1px solid map.get($theme,color-greyscale-light);
    }
  }

  .select-complete {

    .MuiListItemText-multiline {
      margin-top: 0;
      margin-bottom: 0;
    }

    .clients-list-wrapper {

      border-radius: map.get($theme,radius-small);
      border: 1px solid map.get($theme,color-greyscale-light);

      .Mui-selected {
        &::after {
          content: "\f00c";
          font-weight: 900;
          display: block;
          font-family: "Font Awesome 5 Free";
          position: absolute;
          top: 50%;
          right: 1.2rem;
          transform: translateY(-50%);
          color: map.get($theme,color-greyscale-dark);
          font-size: map.get($theme,font0);
          pointer-events: none;
        }
      }
    }
  }

  .client-name {
    font-size: map.get($theme,font2);
    font-weight: 400;
  }

  .cod-cli {
    color: map.get($theme,color-greyscale-disabled);
    font-size: map.get($theme,font1);
    font-weight: 400;
  }

  .address, .city {
    color: map.get($theme,color-greyscale-disabled);
    font-size: map.get($theme,font1);
  }

  .selected-client {
    strong {
      font-weight: bold;
    }
    .label {
      font-size: map.get($theme,font2);
    }
  }

  @for $i from 1 through 10 {
    .font-size-#{$i} {
      font-size: (map.get($theme,font2) + $i*0.1);
    }
    .letter-spacing-#{$i} {
      letter-spacing: (1px * $i);
    }
  }

}

.ts_modal_content_asset {
  position : relative;
  width    : 100%;
  height   : 100%;
  padding  : 1rem;

  .modal_asset_header {
    margin-bottom : 1rem;

    button {
      background-color : transparent;
      border           : none;

      i, i.fa-time:before {
        display   : block;
        font-size : 2rem;
        content   : '\f00d' !important;
      }
    }
  }

  .asset_pdf {
    //overflow: auto;
    //height: 750px;
    height : 100%;

    .rpv-default-layout__toolbar {
      height : 3.5rem;
    }

    .rpv-core__inner-pages.rpv-core__inner-pages--vertical {
      padding-top : 2rem !important;
    }

    .close-btn {
      border       : none;
      margin-right : 7px;

      i.fas:before {
        display : block;
        content : '\f00d' !important;
        //font-weight: 400 !important;
      }
    }
  }

  ._close-btn {
    border       : none;
    margin-right : 7px;

    i.fas:before {
      display : block;
      content : '\f00d' !important;
      //font-weight: 400 !important;
    }
  }

  .image_asset {
    img {
      width     : 100%;
      max-width : 65vh;
      height    : auto;
    }
  }

  video {
    width  : 100%;
    height : auto;
  }

  video, .image_asset img {
    max-height: 90vh;
  }
}

.ts_modal_content_scroll {
  overflow : auto;
}

.ts_modal_content_small {
  padding    : map.get($theme,m5);
  width      : 40rem;
  max-width  : calc(100vw - 3rem);
  height     : auto;
  max-height : calc(100vh - 3rem);
  position   : relative;
  z-index    : 1;
}

.ts_modal_content_medium {
  padding    : map.get($theme,m5);
  width      : 60rem;
  max-width  : calc(100vw - 3rem);
  height     : auto;
  max-height : calc(100vh - 3rem);
  position   : relative;
  z-index    : 1;
}

.ts_modal_content_large {
  padding    : map.get($theme,m5);
  width      : 70rem;
  max-width  : calc(100vw - 3rem);
  height     : auto;
  max-height : calc(100vh - 3rem);
  position   : relative;
  z-index    : 1;

  &.extra_width {
    width : 80rem;
  }
}

.ts_modal_content_large2 {
  padding    : 3.2rem 0.5rem 0.2rem 0.5rem;
  //width: 70vw;
  max-width  : calc(100vw - 3rem);
  height     : auto;
  //min-height: calc(50vw - 3rem);
  max-height : calc(100vh - 3rem);
  position   : relative;
  z-index    : 1;
}

.ts_modal_content_xlarge {
  padding    : map.get($theme,m5);
  max-width  : calc(100vw - 3rem);
  height     : auto;
  max-height : calc(100vh - 3rem);
  position   : relative;
  z-index    : 1;
}

//css per modale fatture
.pdf_content {
  overflow : auto;
  padding  : 4rem 1rem 1rem 1rem;
  width    : 100vh;
  height   : 90vh;
  position : relative;
  z-index  : 1;
}

.ts_modal_close {
  position : absolute;
  right    : 0;
  top      : 0;
  padding  : 1rem;
  cursor   : pointer;
  z-index  : 2;
  width    : 3.4rem;
  height   : 3.4rem;

  i.fas:before {
    display   : block;
    font-size : 2rem;
    content   : '\f00d' !important;
  }
}

.ts_modal_download {
  position : absolute;
  right    : 0;
  top      : 0;
  padding  : 1rem;
  cursor   : pointer;
  z-index  : 2;
  width    : 3.4rem;
  height   : 3.4rem;

  i.fas:before {
    display   : block;
    font-size : 1.8rem;
    color     : black;
  }
}

.modal_success_icon {
  text-align    : center;
  margin-bottom : map.get($theme,m4);

  i {
    display   : block;
    font-size : 10rem;
    color     : map.get($theme,color-secondary-medium);
  }
}

.modal_fail_icon {
  text-align    : center;
  margin-bottom : map.get($theme,m4);

  i {
    display   : block;
    font-size : 10rem;
    color     : map.get($theme,color-warning-medium);
  }
}

.modal-img {
  max-width : 100%;
}

.slider {
  max-height : 70vh;
}

.box-img, .box-video {
  height              : 15vh;
  //width: 20vh;
  //border-radius: 0.375rem;
  background-repeat   : no-repeat;
  background-size     : cover;
  background-position : center center;
}

.box-img:hover {
  opacity : 0.6;
}

.btn-over-img {
  color     : white;
  font-size : 3rem;
}

.box-video {
  border:1px solid map.get($theme,color-greyscale-black);
  i {
    color: map.get($theme,color-greyscale-black);
  }
}

.modal-update {
  p {
    width         : 100% !important;
    display       : block;
    font-size     : 14px;
    margin-bottom : 20px;
  }

  img {
    text-align : center;
    margin     : 10px 0;
  }
}

.OLD_modal-pdf {
  //position: relative;
  overflow-x : hidden;
  //overflow: auto;
  z-index    : 99999;

  //padding: 0 1rem;
  width      : auto;
  max-width  : calc(100vw - 3rem);
  height     : auto;
  max-height : calc(100vh - 3rem);

  .modal-header {
    background-color : black;
    color            : white;
    display          : flex;
    flex-wrap        : wrap;
    align-items      : center;
    justify-content  : end;
  }

  .modal-body {
    padding : 0;

    canvas {
      height : 80vh !important;
      width  : 70vw !important;
    }
  }

  .modal-footer {
    display          : flex;
    flex-wrap        : wrap;
    align-items      : center;
    justify-content  : space-between;
    background-color : black;
    color            : white;
  }

  .modal-btn {
    padding          : 0.5rem 1.3rem;
    //background-color: #2A2A2A;
    background-color : black;
    color            : white;
    border           : none;
    font-size        : 1.5rem;

    i {
      margin-right : 1rem;
    }

    &:hover {
      opacity : 0.8;
    }
  }
}

@media screen and (max-width : 992px) {
  .pdf_content {
    width  : 43vh;
    height : 67vh;
  }

  .ts_modal_content_large2 {
    width : 90vw;
  }

  .modal-pdf {
    overflow : hidden;

    canvas {
      height : 200px !important;
      width  : 360px !important;
    }
  }
}

// TODO sass migration error suppression
//@media screen and (max-width : 768px) {
//  position : relative;
//  z-index  : 99999;
//}

@media screen and (max-width : 400px) {
  .ts_modal_content_asset {
    //padding: 1rem 0.5rem 0.5rem 0.5rem;

    //width: auto;
    //min-width: 30rem;
    //max-width: calc(100vw - 10rem);

    //height: auto;
    //min-height: 30rem;
    //max-height: calc(100vh - 10rem);

    position : relative;
    z-index  : 99999;
  }

  .ts_modal_frame_fullscreen {
    width : 95%;
  }

  .ts_modal_frame {
    width : 95%;
  }
}
