@use 'sass:map';
@use '../theming' as *;

.cookie-consent-banner-wrapper {
  padding          : map.get($theme,m3) map.get($theme,m4);
  font-size        : map.get($theme,font2);
  font-weight      : normal;
  background-color : map.get($theme,accent-white) !important;
  color            : map.get($theme,accent-black) !important;
  box-shadow       : 0 0 10px rgba(map.get($theme,color-greyscale-darker),0.25);

  a {
    font-weight     : bold;
    text-decoration : underline;
    color           : map.get($theme,accent-black) !important;
  }

  .cookie-consent-banner-button {
    padding          : map.get($theme,m2) map.get($theme,m4) !important;
    text-transform   : uppercase;
    border-radius    : map.get($theme,radius-medium) !important;
    background-color : map.get($theme,accent-dark-green) !important;
    color            : map.get($theme,accent-white) !important;
  }
}
