@use "sass:color";
@use 'sass:map';
@use '../theming' as *;

.avatar {
  background-color: map.get($theme,color-greyscale-medium);
  border-radius: 100%;
  font-weight: bold;
  text-align: center;

  &.small {
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    font-size: 1.3rem;
  }
  &.medium {
    width: 5.3rem;
    height: 5.3rem;
    line-height: 5.3rem;
    font-size: 2.4rem;
  }
  &.large {
    width: 8.3rem;
    height: 8.3rem;
    line-height: 8.3rem;
    font-size: 3.6rem;
  }

  &.blue {
    background-color: color.adjust(map.get($theme,color-lightblue-300), $lightness:20%);
  }
  &.green {
    background-color: color.adjust(map.get($theme,color-green-300), $lightness:20%);
  }
}
