@use 'sass:map';
@use '../theming' as *;

// Simple wysiwyg
//
// https://www.npmjs.com/package/react-simple-wysiwyg
.rsw-editor {
	border-color: map.get($theme,color-greyscale-dark);
	.rsw-btn {
		font-size: map.get($theme,font2);
		button[title="Numbered list"] {
			display: none;
		}
	}
	.rsw-ce {
		font-size: map.get($theme,font2);
		background-color: white;
	}
}
