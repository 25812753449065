@use 'sass:map';
@use '../theming' as *;

.ts_sidebar {
    display: flex;
    flex-direction: column;
    transition: all 500ms 0ms cubic-bezier(.86, 0, .07, 1);
    position: relative;
    padding-bottom: 50px;

    &.ts_sidebar_minimized {

        .ts_sidebar_item_label,
        .ts_sidebar_expand {
            opacity: 0;
        }

        .ts_sidebar_item_child {
            &.open {
                opacity: 0;
                height: 0;
            }
        }

        .ts_sidebar_toggle i {
            transform: rotate(180deg);
        }
    }
}

.fixed_sidebar_menu {
    position: absolute;
    left: 0;
    bottom: 0;

    .ts_sidebar_item {
        background: map.get($theme,color-greyscale-darker);
    }
}

.sidebar-hoverable {
    .ts_sidebar {
        &.ts_sidebar_minimized {
            &:hover {
                max-width: map.get($theme,sidebar-width) !important;

                .ts_sidebar_item_label,
                .ts_sidebar_expand {
                    opacity: 1;
                }

                .ts_sidebar_item_child {
                    &.open {
                        opacity: 1;
                        height: auto;
                    }
                }
            }
        }
    }
}

.ts_sidebar_body {
    background-color: map.get($theme,color-sidebar);
    flex: 1;
    display: flex;
    overflow: auto;
    //overflow-x: hidden;
    overflow-x: visible;
}

.sidebar_menu_list {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.ts_sidebar_item.ts_sidebar_item_nohover {
    padding: 0;
    width: 100%;
    overflow: hidden;
    transition: map.get($theme,speed-fast);

    .ts_sidebar_item_inner {
        padding: map.get($theme,m3) map.get($theme,m5);
        width: map.get($theme,sidebar-width);
    }

}

.ts_sidebar_item {
    color: white;

    font-size: map.get($theme,font2);
    width: map.get($theme,sidebar-width);
    cursor: pointer;
    position: relative;

    .ts_sidebar_item_title {
        padding: map.get($theme,m3) map.get($theme,m5);
    }


    &.has_children {
        .has_child_arrow {
            transition: 0.3s;
        }

        &.open {
            background-color: map.get($theme,color-greyscale-darker);

            .ts_sidebar_item_child {
                display: block;

                .ts_sidebar_item_title {

                    padding: map.get($theme,m2) map.get($theme,m5);


                    &.active {
                        //background-color: map.get($theme,color-active);
                        font-size: 1.5rem;
                        border-left: solid 4px;

                        padding: map.get($theme,m2) map.get($theme,m5) map.get($theme,m2) calc(#{map.get($theme,m5)} - 4px);

                        &:hover {
                            //background-color: rgba(map.get($theme,color-active), 0.8);
                        }
                    }
                }
            }

            .has_child_arrow {
                transform: rotate(90deg);
            }

        }

        &.active {
            border-left: none;
            padding: 0;
            .ts_sidebar_item_title{
                padding-left: map.get($theme,m5);
            }
            /*background-color: rgba(map.get($theme,color-active), 0.8) !important;*/
        }
    }


    &:hover {
        background-color: map.get($theme,color-greyscale-darker);
    }

    .ts_sidebar_item_label {
        padding-left: map.get($theme,m3);
        transition: all 200ms 0ms cubic-bezier(.86, 0, .07, 1);
    }

    i {
        display: inline-flex;
        width: 1.8rem;
        justify-content: center;
    }

    &.active {
        background-color: map.get($theme,color-active) !important;
        border-left: solid 4px;

        .ts_sidebar_item_title {
            padding-left: calc(#{map.get($theme,m5)} - 4px);
        }

        &:hover {
            //background-color: rgba(map.get($theme,color-active), 0.8);
        }
    }
}

.ts_sidebar_item_child {
    display: none;
    //position: absolute;
    position: relative;
    //left: map.get($theme,sidebar-width);
    left: 0;
    top: 0;
    padding: 0;
    //padding: map.get($theme,m1)*1.5 map.get($theme,m2);
    width: map.get($theme,sidebar-width);
    background-color: map.get($theme,color-active);
    transition: all 0ms 0ms cubic-bezier(.86, 0, .07, 1);
}

.style_sidebar_subitem {
    /*padding: map.get($theme,m1)*1.5 map.get($theme,m1);*/
    padding: 0;
    display: block;

    .fa, .fas {
        font-size: 3px;
        line-height: map.get($theme,font2);
        transform: translate(0px, -3px);
        margin-right: 12px;
    }
}

.sidebar_menu_bottom {
    display: none;
}

.ts_sidebar_menu_span {
    flex: 1;
}

.scrollarea.sidebar-scroll-area .scrollbar-container.vertical .scrollbar {
    background: #fff;
    margin-left: 1px;
}

.base-overlay {
    display: block;
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    z-index: 1;
    opacity: 0;
    transition: all 500ms 0ms cubic-bezier(.86, 0, .07, 1);
}

.divider-horizontal {
    height: 1px;
    background-color: white;
    width: auto;
    margin: 0 2rem 0 2rem;
}

/* Solo Desktop */

@media screen and (min-width: 993px) {

    /* Regole per gestire l'animazione della sidebar
     mantenendo fissa la larghezza di ts_body */
    body.sidebar-closed {

        .ts_base {
            padding-left: 60px;
            position: relative;
            transition: all 500ms 0ms cubic-bezier(.86, 0, .07, 1);
        }

        .ts_sidebar {
            position: fixed;
            left: 0;
            top: 0;
            z-index: 999;
        }
    }
}

/* Tablet e smartphone */

@media screen and (max-width: 992px) {
    .ts_sidebar_header {
        display: none;
    }

    .ts_sidebar {
        position: fixed;
        z-index: 4;
        transform: translateX(-100%);
        padding-top: map.get($theme,m6);
        padding-bottom: 0;
        overflow-x: hidden;

        &.ts_sidebar_expanded {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 100;
            transform: translateX(0);
        }

        .ts_sidebar_body {
            display: block;

            .sidebar_menu_list {
                .ts_sidebar_menu {
                    .ts_sidebar_item {
                        width: 100%;
                        font-size: 1.8rem;

                        .ts_sidebar_item_child {
                            width: 100%;
                        }

                    }
                }
            }
        }

        .fixed_sidebar_menu {
            display: none;
        }

    }

    .sidebar-open {
        .base-overlay {
            left: 0;
            opacity: 1;
        }
    }

}


/* Solo Tablet */

@media screen and (max-width: 992px) and (min-width: 768px) {
    .ts_sidebar {
        max-width: 30rem !important;
    }
}

/* Smartphone */

@media screen and (max-width: 767px) {
    .ts_sidebar {
        max-width: calc(100% - 60px) !important;
    }
    .options-mobile {
        margin-left: 2rem;
        font-size: 1.5rem;
    }
    .version {
        padding: 0;
        color: white;
        font-size: 1.5rem;
    }
}

/*
@media screen and (max-width: 767px) {

  .ts_sidebar_header {
    display: none;
  }

  .ts_sidebar_body {
    display: block;
    flex: auto;
  }

  .ts_sidebar_menu_span {
    flex: 0;
  }

  .ts_sidebar {
    position: fixed;
    z-index: 4;
    width: 100%;
    max-width: 100% !important;
    transform: translateX(-100%);
    padding-top: map.get($theme,m9);
    padding-bottom: map.get($theme,m7);
    overflow-x: hidden;
  }

  .ts_sidebar_minimized .ts_sidebar_item_label {
    display: block;
  }


  .ts_sidebar_expanded {
    max-width: 100% !important;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    transform: translateX(0);
  }

  .sidebar_menu_bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    color: white;
    padding: map.get($theme,m5)*1.3 0;
    display: flex;
    width: 100%;
    background: map.get($theme,color-greyscale-darker);

    & > div {
      flex: 1;
      justify-content: center;
      display: inline-flex;
      cursor: pointer;
    }

    i {
      font-size: 3rem;
    }
  }

  .ts_sidebar_item {
    width: 100%;
    font-size: map.get($theme,font3)*.9;
    padding: map.get($theme,m4) 0 map.get($theme,m4) 0;

    i {
      font-size: 3rem;
      width: 4rem;
      box-sizing: content-box;
      padding-right: map.get($theme,m5);
    }

    &:hover {
      background-color: transparent;

      .ts_sidebar_item_child {
        display: none;
      }
    }
  }

  .ts_sidebar_item_title {
    display: flex;
    flex-direction: row-reverse;
    padding: map.get($theme,m3) map.get($theme,m5);
  }

  .ts_sidebar_item_label {
    padding-right: map.get($theme,m5);
    padding-left: 0;
  }

  .ts_sidebar_menu_close {
    position: absolute;
    top: map.get($theme,m4);
    right: map.get($theme,m5)*1.1;
    color: white;

    i {
      font-size: 3rem;
      width: 4rem;
      box-sizing: content-box;
      display: inline-flex;
      justify-content: center;
    }
  }

  .ts_sidebar_item_child {
    position: static;
    width: 100%;
    margin: map.get($theme,m2) 0 0 0;
    padding: map.get($theme,m3) map.get($theme,m8)*.95 map.get($theme,m3) map.get($theme,m2);
  }

  .ts_sidebar_item_child.active {
    display: block !important;
  }


}
*/
