@use "sass:color";
@use 'sass:map';
@use '../theming' as *;

.ts_card {
	padding: 3rem;
	border: 1px solid map.get($theme,color-greyscale-light);
	margin-bottom: 2rem;
	font-size: map.get($theme,font2);

	&:last-child {
		margin-bottom: 0;
	}

	p.date {
		color: map.get($theme,color-primary-light);
	}

	.attachments a {
		cursor: pointer;
	}

	.notifications {
		.notify {
			.check {
				span.icon {
					background-color: map.get($theme,color-success-medium);
					color: white;
					display: inline-block;
					border-radius: 100%;
					width: 17px;
					height: 17px;
					line-height: 17px;
					text-align: center;

					i.fas {
						font-size: 1.1rem;
					}
				}

				span.lbl {
					color: map.get($theme,color-success-medium);
					text-transform: uppercase;
					font-size: 1.3rem;
				}

				&.orange {
					span.icon {
						background-color: map.get($theme,color-orange-300);
					}

					span.lbl {
						color: map.get($theme,color-orange-300);
					}
				}

				&.green {
					span.icon {
						background-color: map.get($theme,color-success-medium);
					}

					span.lbl {
						color: map.get($theme,color-success-medium);
					}
				}
			}
		}
	}

	&.blue {
		border-color: map.get($theme,color-lightblue-300);
		background-color: color.adjust(map.get($theme,color-lightblue-300), $lightness:31%);
	}

	&.green {
		border-color: map.get($theme,color-green-300);
		background-color: color.adjust(map.get($theme,color-green-300), $lightness:33%);
	}

	&_disabled {
		position: relative;
		opacity: 0.7;
		background-color: map.get($theme,color-greyscale-lighter) !important;
		border-color: map.get($theme,color-greyscale-dark) !important;

		.row {
			opacity: 0.7;
		}

		&::after {
			content: attr(data-card-label);
			position: absolute;
			right: 1rem;
			bottom: 0.5rem;
			font-size: 2rem;
			letter-spacing: 2px;
			font-weight: bold;
			text-transform: uppercase;
			color: map.get($theme,color-greyscale-dark);
		}

		.avatar {
			background-color: color.adjust(map.get($theme,color-greyscale-dark), $lightness:50%);
		}

		.notify .check.orange span {
			&.icon {
				background-color: color.adjust(map.get($theme,color-greyscale-dark), $lightness:50%) !important;
			}

			&.lbl {
				color: color.adjust(map.get($theme,color-greyscale-dark), $lightness:50%) !important;
			}
		}
	}
}
